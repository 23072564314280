.mv {
  // height: 668px;
  height: auto;
  overflow: hidden;

  @include mq(sp) {
  }
}

.mv__wrapper {
  display: flex;
  align-items: center;
  padding: 0 30px;
  @include mq(sp) {
    display: block;
    padding: 0 15px;
  }
}

.mv__img {
  // max-width: 664px;
  width: 49.53%;
  @include mq(sp) {
    width: 100%;
    margin: 30px auto;
  }
}

.catchcopy {
  margin-left: 3.3%;
  position: relative;
  max-width: 514px;
  width: 49.53%;
  text-align: center;
  color: $navy;
  font-size: 2.8rem;
  line-height: 1.5;
  font-weight: 600;
  @include mq(sp) {
    margin: 0 auto;
    width: 100%;
    font-size: 1.8rem;
  }
}

.line-1 {
  max-width: 100%;
  position: absolute;
  top: -200px;
  right: -150px;
}

.line-2 {
  position: absolute;
  top: 160px;
  left: 120px;
  transform: rotate(65deg);

  @include mq(sp) {
    img {
      width: 100px;
    }
    top: 50px;
    right: -42px;
    transform: rotate(25deg);
  }
}

.line-3 {
  overflow: hidden;

  position: absolute;
  top: 360px;
  right: -120px;
  transform: rotate(-80deg);
}
