/* top-page aboutセクション
---------------------------- */
.about {
  // height: 1000px;
  margin-top: 50px;
  background: #efefef;
  border-radius: 0 50px 0 50px;
  // padding: 64px 0 144px 50px;
  @include mq(sp) {
    // padding: 10px;
    border-radius: 0 100px 0 50px;
  }
}
