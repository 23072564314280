.footer {
  background: linear-gradient(
    to right,
    $black 0%,
    $black 40%,
    $navy 40%,
    $navy 100%
  );
}

.footer__inner {
  display: flex;
  max-width: 984px;
  margin: 0 auto;
  @include mq(sp) {
    display: block;
  }
}
.footer-left {
  background: $black;
  color: $gray;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 7%;
  width: 40%;
  @include mq(sp) {
    padding-right: 0;
    width: 100%;
  }
}

.footer__logo {
  width: 152px;
  @include mq(sp) {
    width: 127px;
  }
}

.footer__info {
  margin: 24px 0;
  padding: 13px 0 16.5px;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
}

.footer__name {
  font-size: 1.4rem;
  padding-bottom: 15px;
}

.footer__address,
.footer__email {
  font-size: 1.1rem;
  padding-bottom: 15px;
  line-height: 1.5;
}

.footer__number {
  font-size: 1rem;
}

.footer__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__sns {
  font-size: 2rem;
  a {
    margin-right: 7px;
  }
}

.privacy {
  font-size: 1.2rem;
}

.footer__right {
  background: $navy;

  width: 60%;
  color: $white;
  @include mq(sp) {
    // background: $navy;
    width: 100%;
  }
}

.footer__nav {
  display: flex;
  padding-left: 10%;
  padding-right: 10px;
  padding-top: 48px;
  @include mq(sp) {
    display: block;
    padding-top: 33px;
    padding-left: 25px;
  }
}

.footer__links__left {
  @include mq(pc) {
    margin-right: 13%;
  }
}

.footer__links__right .footer__links__item {
  margin-bottom: 60px;
  @include mq(sp) {
    margin-bottom: 36px;
  }
}

.footer__links__item {
  font-size: 2rem;
  font-family: $en;
  @include mq(sp) {
    font-size: 1.8rem;
  }
  span {
    font-size: 1.1rem;
    margin-left: 16px;
    vertical-align: middle;
  }
}

.sub__menu {
  margin-top: 32px;
  margin-bottom: 60px;
  @include mq(sp) {
    margin-top: 21px;
    margin-bottom: 36px;
    display: flex;
    flex-wrap: wrap;
  }
}

.sub__menu__item {
  font-size: 1.4rem;
  opacity: 0.88;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @include mq(sp) {
    font-size: 1.2rem;
    margin-right: 30px;
  }
}

.copyright {
  font-size: 1.1rem;
  color: #ffffffe0;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10%;
  @include mq(sp) {
    text-align: center;
    padding-left: 0;
  }
}
