.message__inner {
  margin-top: 72px;
  display: flex;
  align-items: top;
  @include mq(sp) {
    display: block;
    margin-top: 35px;
    margin: 30px auto 0;
  }
}









.message__img {
  width: 376px;
  height: 376px;
  img {
    height: 100%;
    width: 100%;
    object-fit:cover;
  }
  @include mq(sp) {
    width: 80%;
    height: auto;
    margin: 0 auto;
  }
}

.message__text {
  width:58.7%;
  margin-left: 3.7%;
  text-align: justify;
  @include mq(sp) {
    width: 100%;
    margin-top: 35px;
    margin-left: 0;
  }
  p {
    font-size: 1.6rem;
    font-family: $mincho;
    line-height: 1.5;
    margin-bottom: 24px;
    letter-spacing: 0.9px;
    @include mq(sp) {
      font-size: 1.5rem;
      line-height: 1.2;
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
      text-align: right;
      font-weight: 600;
      font-size: 1.8rem;
      letter-spacing: 1.01px;
      line-height: 2;
      @include mq(sp) {
        font-size: 1.8rem;
      }
    }
  }
}
