.news-entry {
  max-width: 800px;
  margin: 70px auto 0;
  @include mq(sp) {
    margin-top: 40px;
  }
}

.news-entry-title {
  font-size: 3rem;
  letter-spacing: 2.4px;
  line-height: 1.8;
  font-weight: 600;
  margin-bottom: 30px;
  @include mq(sp) {
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}

.news-entry-img {
  max-width: 800px;
  padding-top: 50%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
  }
  @include mq(sp) {
    width: 100%;
    height: 50%;
  }
}

.news-entry-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 41px;
  @include mq(sp) {
    margin-bottom: 15px;
  }
}

.news-entry-label {
  display: inline-block;
  color: $white;
  background: $blue;
  padding: 9px 13px;
  font-size: 1.2rem;
  border-radius: 16px;
  letter-spacing: 1.28px;
  @include mq(sp) {
    padding: 3px 8px;
  }
}

.news-entry-published {
  font-size: 1.6rem;
  font-family: $en;
  letter-spacing: 1.28px;
}

.news-entry-text {
  letter-spacing: 1.44px;
  opacity: 0.8;
  margin-bottom: 80px;
  line-height: 1.7;
  @include mq(sp) {
    margin-bottom: 20px;
    line-height: 1.2;
  }
}

.news-entry-body {
  h2 {
    font-size: 2rem;
    line-height: 1.8;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
    background: $gray;
    border-top: 6px solid $blue;
    padding: 33px 38px;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 1.6rem;
      line-height: 1.2;
      padding: 10px;
    }
  }
  h3 {
    background: $gray;
    padding: 33px 38px;
    font-size: 2rem;
    line-height: 1.8;
    letter-spacing: 1.6px;
    margin-bottom: 40px;
    @include mq(sp) {
      margin-bottom: 20px;
      margin-bottom: 20px;
      font-size: 1.5rem;
      line-height: 1.2;
      padding: 10px;
    }
  }
  p {
    letter-spacing: 1.44px;
    opacity: 0.8;
    margin-bottom: 40px;
    line-height: 1.7;
    padding: 0 40px;
    @include mq(sp) {
      margin-bottom: 20px;
      line-height: 1.2;
      padding: 0;
    }
  }
}

.news-entry-cv {
  height: 248px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 2px solid $gray;
}

.news-entry-btn {
  margin-top: 48px;
  text-align: center;
}