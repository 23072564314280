.contact {
  margin-top: 86px;
  margin-bottom: 160px;
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
  @include mq(sp) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  p {
    line-height: 1.9125;
    letter-spacing: 1.28px;
    opacity: 0.8;
  }
}

.contact__text {
  font-size: 1.6rem;
  letter-spacing: 1.28px;
  opacity: 0.8;
  line-height: 1.9125;
  @include mq(sp) {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.contact__item {
  margin-bottom: 48px;
  @include mq(sp) {
    margin-bottom: 20px;
  }
  dt {
    margin-bottom: 16px;
    @include mq(sp) {
      margin-bottom: 10px;
    }
  }
}

.contact__form {
  margin-top: 46px;
  @include mq(sp) {
    margin-top: 30px;
  }
}

.contact__label,
.contact__radio__item {
  font-size: 1.6rem;
  letter-spacing: 1.28px;
  opacity: 0.8;
}

.contact__label {
  margin-bottom: 50px;
}

.require-label {
  display: inline-block;
  background: $red;
  color: #fff;
  border-radius: 2px;
  margin-left: 16px;
  width: 40px;
  line-height: 24px;
  height: 24px;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
  text-align: center;
}

.form-select {
  // selectboxリセット
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  // 入力欄のスタイル
  width: 100%;
  height: 48px;
  background: $gray url(../img/select-arrow.svg) no-repeat center right 18px /
    24px 15px;
  border: none;
  border-radius: 4px;
  // 入力欄の中身の調整
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;

  @include mq(sp) {
    font-size: 1.4rem;
  }
  &::-ms-expand {
    display: none;
  }
}

//最初の項目だけ薄い灰色にする
select.is-empty {
  color: #757575;
}

.wpcf7-form-control-wrap {
  // display: block;
}

.wpcf7-form-control.wpcf7-radio {
  display: flex;
  // justify-content: none;
  @include mq(sp) {
    display: initial;
  }
}

span.wpcf7-list-item {
  position: relative;
  // flex-basis: 33%;
  // width: 100%;
  margin-right: 40px;
  @include mq(sp) {
    margin-right: 10px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:not(:first-child) {
    @include mq(sp) {
      display: inline-block;
      padding-top: 20px;
    }
  }
}

.wpcf7-list-item-label {
  color: $black;
  cursor: pointer;
  font-size: 1.6rem;
  opacity: 0.8;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.wpcf7-list-item-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #c8c8c8;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 8px;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

input[type="radio"]:checked + .wpcf7-list-item-label:before {
  background-color: $navy;
  box-shadow: inset 0 0 0 3px #fff;
}



.form-input {
  // text リセット
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  // 入力欄のスタイル
  width: 100%;
  height: 48px;
  background: $gray;
  border: none;
  border-radius: 4px;
  color: inherit;
  font-family: inherit;
  // 入力欄の中身の調整
  padding: 0.4em 0.8em;
  font-size: 1.6rem;
  letter-spacing: 1.28px;
  // padding: 16px 16px;
  @include mq(sp) {
    font-size: 1.4rem;
  }
}

.wpcf7-not-valid-tip {
  color: $red !important;
  font-size: 1.2rem !important;
  letter-spacing: 0.96px !important;
  margin-top: 8px !important;
}

.form-textarea {
  // textarea リセット
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  resize: none;
  // 入力欄のスタイル
  background: $gray;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 240px;
  // 入力欄の中身の調整
  padding: 0.4em 0.8em;
  line-height: (26 / 16);
  color: inherit;
  font-family: inherit;
  font-size: 1.6rem;
  letter-spacing: 1.28px;
  @include mq(sp) {
    height: 180px;
    font-size: 1.4rem;
  }
}

.submit__btn {
  text-align: center;
}
.contact__btn {
  display: inline-block;
  padding: 18px 35px;
  background: $red;
  font-size: 2rem;
  color: $white;
  letter-spacing: 1.6px;
  border-radius: 40px;
  text-align: center;
  transition: opacity .3s;
  border: none;
  @include mq(sp) {
    width: 100%;
  }
  &:hover {
    opacity: 0.8;
  }
}

.confirmation_btn {
  background: $red;
}
