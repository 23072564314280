.pagenation {
  margin-top: 12px;
  margin-bottom: 136px;
  text-align: center;
  @include mq(sp) {
    margin-bottom: 60px;
  }
}

// 通常スタイル
.page-numbers {
  background: $gray;
  color: $navy;
  display: inline-block;
  font-family: $en;
  font-size: 2.4rem;
  margin-right: 24px;
  text-align: center;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-weight: 600;
  &:last-child {
    margin-right: 0;
  }
  @include mq(sp) {
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
  }
}

// 現在のページ
// .page-numbers.current{
//   background: $red;
//   color: $white;
// }

.post-type-archive-voice .pagenation .current {
  background: $red;
  color: #ffffff;
}

.post-type-archive-blog .pagenation .current {
  background: $orange;
  color: #ffffff;
}

.post-type-archive-news .pagenation .current {
  background: $blue;
  color: #ffffff;
}

// 前・後 ページ送りドット
.pagenation a.next,
.pagenation a.prev {
  font-size: 2rem;
}

.page-numbers.dots,
.page-numbers.next,
.page-numbers.prev {
  color: $navy;
  border: none;
  background: initial;
  width: auto;
}
