.service {
  max-width: 864px;
  width: 68.78%;
  margin-top: 100px;
  margin-right: 8.9%;
  margin-left: auto;
  padding-bottom: 100px;
  @include mq(sp) {
    width: 100%;
    margin-top: 60px;
    margin-right: 0;
    margin-bottom: 60px;
  }
}

.service__list {
  margin-top: 56px;
  @include mq(sp) {
    margin-top: 22px;
  }
}

.service__item {
  display: flex;
  align-items: center;
  margin-bottom: 56px;

  @include mq(sp) {
    // font-size: 14px;
    display: block;
    padding: 0 20px;
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.service__item__header {
  max-width: 280px;
  @include mq(sp) {
    margin: 0 auto;
    width: 100%;
  }
}

.service__item__img {
  // padding-top: 61.5385%;
  // position: relative;
  // overflow: hidden;

  // > img {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 100%;
  //   transform: translate(-50%, -50%);
  //   transition: transform 0.4s;
  // }
}

.service__item__body {
  // flex: 0 1 100%;
  width: calc(100% - 280px);
  margin-left: 40px;
  @include mq(sp) {
    width: 100%;
    margin-left: 0;
    margin-top: 21px;
  }
}

.service__item__title {
  font-size: 2.4rem;
  font-weight: bold;
  margin-top: 4px;
  opacity: 0.88;
  @include mq(sp) {
    font-size: 2rem;
  }
}

.service__item__text {
  opacity: 0.8;
  margin-top: 24px;
  line-height: 1.6666;
  letter-spacing: 1.44px;
  @include mq(sp) {
    margin-top: 10px;
  }
}

.service__footer {
  text-align: right;
  margin-top: 38px;
  @include mq(sp) {
    margin-top: 10px;
    text-align: center;
  }
}
