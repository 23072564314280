/*======================================================================
# ブレークポイント
====================================================================== */
$sp: 767px;
$pc: 768px;
// $sp: 959px;
// $pc: 960px;

@mixin mq($media) {
	@if $media == sp { 
		@media screen and (max-width: #{$sp}) {
			/* sp */
			@content;
		}
	}
	@else if $media == pc { 
		@media screen and (min-width: #{$pc}) {
			/* pc */
			@content;
		}
	}
}

// z-indexをMap型で一括管理する
@mixin z-index($key) {
    z-index: map-get($layer, $key);
}

// トラッキングのmixin
@mixin tracking($tracking) {
  letter-spacing: ($tracking / 1000 ) + em;
}
// ↓
// 利用するとき
// p {
//  @include tracking(16);
// }
