.news__items {
  margin: 70px auto 0;
  width: 800px;
  max-width: 100%;

  @include mq(sp) {
    margin-top: 38px;
  }
}

.news__item {
  border-bottom: 2px solid $gray;
}

.info {
  padding: 28px 35px 28px 0;
  display: flex;

  @include mq(sp) {
    display: block;
    padding: 12px 0;
  }
}

.info__meta {
  width: 20.5%;
  padding-right: 20px;

  @include mq(sp) {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 0;
  }
}

.info__contents {
  width: 79.5%;
  @include mq(pc) {
    width: 100%;
  }
}

.info__published {
  display: inline-block;
  font-size: 2rem;
  opacity: 0.56;
  font-family: $en;
  @include mq(sp) {
    font-size: 1.6rem;
    margin-right: 10px;
  }
}

.info__label {
  margin-top: 12px;
  display: inline-block;
  color: $white;
  background: $blue;
  padding: 6px 13px;
  font-size: 1.2rem;
  border-radius: 16px;
  a {
    display: inline-block;
    color: $white;
    background: $blue;
    padding: 6px 13px;
    font-size: 1.2rem;
    border-radius: 16px;
  }
  @include mq(sp) {
    margin-top: 0;
    padding: 3px 8px;
  }
}

.info__title {
  font-weight: 600;
  letter-spacing: 1.44px;
  @include mq(sp) {
    width: 100%;
    margin-top: 10px;
  }
}

.info__text {
  opacity: 0.72;
  margin-top: 16px;
  line-height: 1.6;
  @include mq(sp) {
    margin-top: 10px;
  }
}
