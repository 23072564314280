/*==================================================
# service
====================================================*/

/* service__catch
------------------------------------------------------*/
.service__catch {
  margin-top: 74px;
  margin-bottom: 124px;
  @include mq(sp) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.catch__title {
  font-size: 3.2rem;
  letter-spacing: 2.56px;
  text-align: center;
  line-height: 1.6875;
  @include mq(sp) {
    font-size: 2.4rem;
  }
}

.catch__text {
  margin-top: 52px;
  max-width: 890px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.9rem;
  letter-spacing: 1.52px;
  opacity: 0.8;
  text-align: center;
  line-height: 1.6105;
  @include mq(sp) {
    margin-top: 25px;
  }
}

/* service__about
------------------------------------------------------*/
.service__about {
  background: $gray;
  padding: 82px 0 56px;
  @include mq(sp) {
    padding: 40px 0;
  }
}

.about__img {
  margin-top: 54px;
  margin-right: auto;
  margin-left: auto;
  max-width: 720px;
  padding-top: 42.2%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include mq(sp) {
    margin-top: 20px;
    padding-top: 67.5%;
  }
}

.about__text {
  margin: 40px auto 0;
  max-width: 690px;
  letter-spacing: 1.44px;
  opacity: 0.8;
  line-height: 1.7;
}

.about_btn {
  text-align: center;
  margin-top: 65px;
  @include mq(sp) {
    margin-top: 30px;
  }
}

/* feature
------------------------------------------------------*/
.feature {
  padding: 71px 0 91px;
  @include mq(sp) {
    padding: 40px 0;
  }
}

.feature__items {
  display: flex;
  // flex-wrap: wrap;
  margin-top: 49px;
  margin: 0 auto;
  @include mq(sp) {
    display: block;
    margin-top: 30px;
  }
}

.feature__item {
  // max-width: 296px;
  // カードとカードの間のサイズ*隙間の数/カードの枚数
  width: calc(33.33333333% - 30px * 2 / 3);
  &:not(:last-child) {
    margin-right: 30px;
  }
  @include mq(sp) {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}

.feature__img {
  text-align: center;
  // max-width: 296px;
  padding-top: 67.56%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include mq(sp) {
    max-width: 100%;
  }
}

.feature__title {
  letter-spacing: 1.44px;
  line-height: 1.6;
  margin-top: 24px;
  font-weight: bold;
  text-align: justify;
  @include mq(sp) {
    margin-top: 10px;
    font-size: 1.6rem;
  }
}

.feature__content {
  margin-top: 25px;
  font-size: 1.6rem;
  opacity: 0.8;
  letter-spacing: 1.28px;
  text-align: justify;
  line-height: 1.6;
  @include mq(sp) {
    margin-top: 15px;
  }
}

/* staff
------------------------------------------------------*/
.staff {
  padding: 72px 0 78px;
  background: $gray;
  @include mq(sp) {
    padding: 40px 0;
  }
}

.staff__item {
  display: flex;
  align-items: flex-start;
  margin-top: 72px;
  border-bottom: 2px solid rgba(187, 187, 187, 0.4);
  // padding: 0 40px 40px 47px;
  @include mq(sp) {
    display: block;
    margin-top: 30px;
    border-bottom: none;
    // padding: 0 0 20px;
  }
}

.staff__img {
  width: 29%;
  text-align: center;
  img {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    object-fit: cover;
  }
  @include mq(sp) {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    text-align: center;
  }
}

.staff__name {
  margin-top: 20px;
  text-align: center;
  letter-spacing: 1.44px;
  opacity: 0.88;
}

.staff__body {
  @include mq(pc) {
    width: 70%;
    margin-left: 3.8%;
  }
  @include mq(sp) {
    margin-top: 20px;
  }
}

.staff__catch {
  margin-top: 12px;
  margin-right: 30px;
  opacity: 0.88;
  font-size: 2.2rem;
  letter-spacing: 1.76px;
  @include mq(sp) {
    font-size: 1.8rem;
    text-align: center;
  }
}

.staff__text {
  opacity: 0.88;
  margin-top: 36px;
  line-height: 1.6;
  letter-spacing: 1.44px;
  @include mq(sp) {
    margin-top: 20px;
    font-size: 1.4rem;
  }
}

.staff__footer {
  margin-top: 34px;
  padding-bottom: 80px;
  display: flex;
  // flex-wrap: no-wrap;
  @include mq(sp) {
    padding-bottom: 0;
    display: block;
  }

  & .btn__blue {
    @include mq(pc) {
      margin-left: 3.8%;
    }
    @include mq(sp) {
      margin-top: 20px;
    }
  }
}

/* service__voice
------------------------------------------------------*/
.service__voice {
  margin-top: 80px;
  // margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 40px;
    // margin-bottom: 40px;
  }
}

/* document
------------------------------------------------------*/
.document {
  margin-top: 160px;
  // margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 80px;
    // margin-bottom: 40px;
  }
}

.document__item {
  margin-top: 44px;
  height: 352px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bbbbbb;
}

/* other
------------------------------------------------------*/
.other {
  margin-top: 160px;
  // margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 80px;
    // margin-bottom: 40px;
  }
}

.other .heading3 {
  font-family: $en;
}

.other__inner {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  @include mq(sp) {
    display: block;
  }
}

.service_pre {
  width: 47%;
  margin-right: 3%;
  a {
    display: block;
    height: 120px;
    line-height: 120px;
    text-align: center;
    background: #bbbbbb;
    color: $white;
    position: relative;
  }
  @include mq(sp) {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.service_next {
  width: 47%;
  margin-left: 3%;
  a {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    background: #bbbbbb;
    color: $white;
    position: relative;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   background: url(../img/arrowR.png) no-repeat center center/contain;
    //   width: 48px;
    //   height: 48px;
    //   top: 50%;
    //   right: 10px;
    //   transform: translateY(-50%);
    // }
  }
  @include mq(sp) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
/* contact_area
------------------------------------------------------*/
.contact_area {
  margin-top: 112px;
  margin-bottom: 103px;
  padding: 41px 10px 57px;
  background: $gray;
  @include mq(sp) {
    margin-top: 80px;
    margin-bottom: 40px;
  }
}

.contact_area_title {
  font-size: 2.4rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1.92px;
  @include mq(sp) {
    font-size: 2rem;
  }
}

.contact_area_text,
.contact_area_btn {
  margin-top: 43px;
  text-align: center;
  @include mq(sp) {
    margin-top: 20px;
  }
}
