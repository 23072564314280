.sns_share_btn {
  @include mq(sp) {
    display: none;
  }
}

.sns__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 550px;
  right: 30px;
  z-index: 10;
}

.sns_text {
  font-family: $en;
  color: $navy;
  letter-spacing: 1.12px;
  font-size: 1.4rem;
  margin-bottom: 1em;
}

.sns__link {
  width: 49px;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
