.breadcrumb {
	color: $navy;
	font-size: 1.5rem;
  margin-top: 20px;
	margin-bottom: 14px;
}


.breadcrumb a {
	transition: all 0.3s ease 0s;
  &:first-child {
    font-family: $en;
  }
}

.fa-angle-right {
  color: #2218148F;
  // font-size: 2rem;
}

.breadcrumb i {
	margin-left: 12px;
	margin-right: 12px;
}

.current-item {
  color: #2218148F;
}