.outline_inner {
  max-width: 984px;
  margin: 57px auto 0;
  background: $white;
  padding: 17px 33px 14px 30px;
  @include mq(sp) {
    margin-top: 28px;
    width: 100%;
    padding: 10px;
  }
  dl {
    font-size: 1.5rem;
    display: flex;
    padding: 17px 0;
    border-bottom: 2px solid $gray;
    @include mq(sp) {
      padding: 10px;
      display: block;
    }
  }
  dt {
    color: $navy;
    font-weight: 600;
    width: 20%;
    padding-left: 25px;
    @include mq(sp) {
      padding-left: 0;

      width: 100%;
    }
  }
  dd {
    width: 80%;
    padding-left: 60px;
    @include mq(sp) {
      margin-top: 5px;
      padding-left: 0;
      width: 100%;
    }
    a {
      color: #449AC5;
      text-decoration: underline;
    }
  }
}
