@charset "UTF-8";
/*==========================================================================
# reset - ブラウザの差異や不要なスタイルを無くすためのスタイル
========================================================================== */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, h1, h2, h3, h4, h5, h6, ul, ol, dl, li, dt, dd, p, div, span, img, a, table, tr, th, td, small, button, time, figure {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

li, dd {
  list-style-type: none;
}

header, footer, nav, section, article, aside, figure, figcaption {
  display: block;
}

img {
  border: none;
  vertical-align: bottom;
}

a {
  cursor: pointer;
  text-decoration: none;
}

/*======================================================================
# 変数定義
====================================================================== */
/*======================================================================
# ブレークポイント
====================================================================== */
/*======================================================================
# common - 全体に共通するスタイル
====================================================================== */
html {
  font-size: 62.5%;
  /*標準サイズ*/
}

body {
  font-size: 1.8rem;
  color: #221814;
  font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 767px) {
  body {
    /* sp */
    font-size: 1.4rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea,
select,
button {
  font-family: inherit;
  appearance: none;
  outline: none;
}

select::-ms-expand {
  display: none;
}

button {
  cursor: pointer;
  font-size: inherit;
}

@media screen and (max-width: 767px) {
  /* sp */
  .is-pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  /* pc */
  .is-sp {
    display: none;
  }
}

/* レイアウト */
.l-header {
  width: 100%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  z-index: 20;
}

@media screen and (min-width: 768px) {
  .l-header {
    /* pc */
    padding: 0 30px;
  }
}

@media screen and (max-width: 767px) {
  .l-header {
    /* sp */
    padding: 0 16px;
  }
}

.l-content-heading {
  height: 319px;
  background: url(https://placehold.jp/1366x319.png) no-repeat center center/cover;
}

.l-content-heading__inner {
  margin-top: -75px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.l-wrapper {
  max-width: 1366px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .l-wrapper {
    /* pc */
    padding: 0 30px;
  }
}

@media screen and (max-width: 767px) {
  .l-wrapper {
    /* sp */
    padding: 0 16px;
  }
}

.l-content-wrapper {
  max-width: 1060px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .l-content-wrapper {
    /* pc */
    padding: 0 30px;
  }
}

@media screen and (max-width: 767px) {
  .l-content-wrapper {
    /* sp */
    padding: 0 16px;
  }
}

.section {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .section {
    /* sp */
    padding: 50px 0;
  }
}

.section--top {
  margin-bottom: 160px;
}

@media screen and (max-width: 767px) {
  .section--top {
    /* sp */
    margin-bottom: 80px;
  }
}

.section--profiles {
  padding-bottom: 118px;
}

.section--voice, .section--news {
  padding-top: 0;
}

@media screen and (max-width: 767px) {
  .section--voice, .section--news {
    /* sp */
    padding-top: 0;
  }
}

.bg-gray {
  background: #EFEFEF;
}

.blog-entry {
  max-width: 800px;
  margin: 74px auto 0;
}

@media screen and (max-width: 767px) {
  .blog-entry {
    /* sp */
    margin-top: 40px;
  }
}

.blog-entry-title {
  font-size: 3rem;
  letter-spacing: 2.4px;
  line-height: 1.8;
  font-weight: 600;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .blog-entry-title {
    /* sp */
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}

.blog-entry-img {
  max-width: 800px;
  padding-top: 50%;
  position: relative;
}

.blog-entry-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .blog-entry-img {
    /* sp */
    width: 100%;
    height: 50%;
  }
}

.blog-entry-label {
  position: absolute;
  bottom: 15px;
  left: 17px;
  display: inline-block;
  color: #ffffff;
  background: #D3521E;
  padding: 9px 13px;
  font-size: 1.4rem;
  border-radius: 16px;
  letter-spacing: 1.28px;
}

@media screen and (max-width: 767px) {
  .blog-entry-label {
    /* sp */
    padding: 3px 10px;
    font-size: 1.2rem;
    bottom: 8px;
    left: 8px;
  }
}

.blog-entry-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 41px;
}

@media screen and (max-width: 767px) {
  .blog-entry-meta {
    /* sp */
    display: block;
    margin-bottom: 15px;
  }
}

.blog-tag-wrap {
  display: flex;
  flex-wrap: wrap;
}

.blog-entry-tag:not(:last-child) {
  margin-right: 16px;
}

@media screen and (max-width: 767px) {
  .blog-entry-tag:not(:last-child) {
    /* sp */
    margin-right: 9px;
  }
}

.blog-entry-tag a {
  display: inline-block;
  font-size: 1.4rem;
  color: #182350;
  border: 1px solid #182350;
  border-radius: 4px;
  padding: 9px 18px;
}

@media screen and (max-width: 767px) {
  .blog-entry-tag a {
    /* sp */
    font-size: 1.2rem;
    padding: 5px 9px;
    margin-bottom: 10px;
  }
}

.blog-entry-published {
  font-size: 1.6rem;
  font-family: "Josefin Sans", sans-serif;
  letter-spacing: 1.28px;
}

.blog-entry-text {
  letter-spacing: 1.44px;
  opacity: 0.8;
  margin-bottom: 80px;
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .blog-entry-text {
    /* sp */
    margin-bottom: 20px;
    line-height: 1.2;
  }
}

.blog-entry-body h2 {
  font-size: 2rem;
  line-height: 1.8;
  margin-bottom: 40px;
  letter-spacing: 1.6px;
  background: #EFEFEF;
  border-top: 6px solid #D3521E;
  padding: 33px 38px;
}

@media screen and (max-width: 767px) {
  .blog-entry-body h2 {
    /* sp */
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 1.2;
    padding: 10px;
  }
}

.blog-entry-body h3 {
  background: #EFEFEF;
  padding: 33px 38px;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: 1.6px;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .blog-entry-body h3 {
    /* sp */
    margin-bottom: 20px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    line-height: 1.2;
    padding: 10px;
  }
}

.blog-entry-body p {
  letter-spacing: 1.44px;
  opacity: 0.8;
  margin-bottom: 40px;
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .blog-entry-body p {
    /* sp */
    margin-bottom: 20px;
    line-height: 1.2;
  }
}

.blog-entry-cv {
  height: 248px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 2px solid #EFEFEF;
}

.blog-entry-btn {
  margin-top: 48px;
  text-align: center;
}

/* 固定ページblogとトップページのブログ
---------------------------- */
.blog__items {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .blog__items {
    /* sp */
    display: block;
  }
}

.blog__item {
  width: calc(33.33333333% - 56px * 2 / 3);
  margin-right: 5.6%;
  margin-top: 40px;
  padding-bottom: 52px;
  border-bottom: 2px solid #EFEFEF;
}

@media screen and (max-width: 767px) {
  .blog__item {
    /* sp */
    width: 100%;
    margin-right: 0;
  }
}

.blog__item:nth-child(3n) {
  margin-right: 0;
}

.blog__img {
  max-width: 296px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .blog__img {
    /* sp */
    width: 100%;
    margin: 0 auto;
  }
}

.blog__body {
  color: #6f7579;
}

.blog__label {
  display: inline-block;
  background: #D3521E;
  padding: 10px 17px;
  border-radius: 16px;
  margin-top: 16px;
  color: #ffffff;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
}

.blog__content {
  margin-top: 16px;
  font-size: 1.6rem;
  opacity: 0.88;
  font-weight: 600;
  letter-spacing: 1.44px;
  line-height: 1.4222;
}

/* トップのブログのスタイル */
.blog--top {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto 160px;
}

@media screen and (max-width: 767px) {
  .blog--top {
    /* sp */
    margin-bottom: 80px;
  }
}

.blog__item--top {
  margin-top: 40px;
  padding-bottom: 0;
  border-bottom: none;
}

.blog__footer {
  text-align: center;
  margin-top: 51px;
}

.breadcrumb {
  color: #182350;
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 14px;
}

.breadcrumb a {
  transition: all 0.3s ease 0s;
}

.breadcrumb a:first-child {
  font-family: "Josefin Sans", sans-serif;
}

.fa-angle-right {
  color: #2218148F;
}

.breadcrumb i {
  margin-left: 12px;
  margin-right: 12px;
}

.current-item {
  color: #2218148F;
}

/* ボタン
---------------------------- */
.btn {
  display: inline-block;
  padding: 13px 24px;
  border-radius: 20px;
}

.btn__white {
  display: inline-block;
  padding: 12px 41px;
  color: #182350;
  letter-spacing: 1.44px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #182350;
  border-radius: 24px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .btn__white {
    /* sp */
    padding: 10px 20px;
    width: 100%;
  }
}

.btn__contact {
  display: inline-block;
  padding: 13px 24px;
  border-radius: 20px;
  color: #ffffff;
  background: #AE1E23;
  letter-spacing: 1.2px;
  font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
  .btn__contact {
    /* sp */
    width: 100%;
    text-align: center;
  }
}

.btn--lg {
  display: inline-block;
  border-radius: 40px;
  padding: 22px 124px;
  letter-spacing: 1.6px;
}

@media screen and (max-width: 767px) {
  .btn--lg {
    /* sp */
    padding: 13px 24px;
  }
}

.btn__description {
  display: inline-block;
  background: #182350;
  color: #ffffff;
  letter-spacing: 1.44px;
  padding: 19px 35px;
  border-radius: 50px;
  box-shadow: 0 16px 32px #18235029;
}

@media screen and (max-width: 767px) {
  .btn__description {
    /* sp */
    padding: 15px 20px;
    width: 100%;
  }
}

.btn__blue {
  display: inline-block;
  background: #182350;
  color: #ffffff;
  letter-spacing: 1.44px;
  padding: 12px 41px;
  border-radius: 50px;
  box-shadow: 0 16px 32px #18235029;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .btn__blue {
    /* sp */
    padding: 10px 20px;
    width: 100%;
  }
}

.btn__blog,
.btn__news {
  display: inline-block;
  text-align: center;
  background: #221814;
  color: #ffffff;
  letter-spacing: 1.44px;
  padding: 19px 86px;
  border-radius: 50px;
  box-shadow: 0 16px 32px #18235029;
}

@media screen and (max-width: 767px) {
  .btn__blog,
  .btn__news {
    /* sp */
    padding: 10px 20px;
    width: 100%;
  }
}

.btn__swiper {
  display: inline-block;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.6rem;
  text-align: center;
  background: #221814;
  color: #ffffff;
  letter-spacing: 1.28px;
  padding: 12px 27px;
  border-radius: 50px;
  box-shadow: 0 16px 32px #18235029;
}

@media screen and (max-width: 767px) {
  .btn__swiper {
    /* sp */
  }
}

.btn__single {
  display: inline-block;
  background: #221814;
  border-radius: 28px;
  color: #ffffff;
  width: 296px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  box-shadow: 0 3px 6px #00000029;
}

@media screen and (max-width: 767px) {
  .btn__single {
    /* sp */
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
}

.contact {
  margin-top: 86px;
  margin-bottom: 160px;
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .contact {
    /* sp */
    margin-top: 40px;
    margin-bottom: 80px;
  }
}

.contact p {
  line-height: 1.9125;
  letter-spacing: 1.28px;
  opacity: 0.8;
}

.contact__text {
  font-size: 1.6rem;
  letter-spacing: 1.28px;
  opacity: 0.8;
  line-height: 1.9125;
}

@media screen and (max-width: 767px) {
  .contact__text {
    /* sp */
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.contact__item {
  margin-bottom: 48px;
}

@media screen and (max-width: 767px) {
  .contact__item {
    /* sp */
    margin-bottom: 20px;
  }
}

.contact__item dt {
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .contact__item dt {
    /* sp */
    margin-bottom: 10px;
  }
}

.contact__form {
  margin-top: 46px;
}

@media screen and (max-width: 767px) {
  .contact__form {
    /* sp */
    margin-top: 30px;
  }
}

.contact__label,
.contact__radio__item {
  font-size: 1.6rem;
  letter-spacing: 1.28px;
  opacity: 0.8;
}

.contact__label {
  margin-bottom: 50px;
}

.require-label {
  display: inline-block;
  background: #AE1E23;
  color: #fff;
  border-radius: 2px;
  margin-left: 16px;
  width: 40px;
  line-height: 24px;
  height: 24px;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
  text-align: center;
}

.form-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 48px;
  background: #EFEFEF url(../img/select-arrow.svg) no-repeat center right 18px/24px 15px;
  border: none;
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;
}

@media screen and (max-width: 767px) {
  .form-select {
    /* sp */
    font-size: 1.4rem;
  }
}

.form-select::-ms-expand {
  display: none;
}

select.is-empty {
  color: #757575;
}

.wpcf7-form-control.wpcf7-radio {
  display: flex;
}

@media screen and (max-width: 767px) {
  .wpcf7-form-control.wpcf7-radio {
    /* sp */
    display: initial;
  }
}

span.wpcf7-list-item {
  position: relative;
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  span.wpcf7-list-item {
    /* sp */
    margin-right: 10px;
  }
}

span.wpcf7-list-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  span.wpcf7-list-item:not(:first-child) {
    /* sp */
    display: inline-block;
    padding-top: 20px;
  }
}

.wpcf7-list-item-label {
  color: #221814;
  cursor: pointer;
  font-size: 1.6rem;
  opacity: 0.8;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.wpcf7-list-item-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #c8c8c8;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 8px;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

input[type="radio"]:checked + .wpcf7-list-item-label:before {
  background-color: #182350;
  box-shadow: inset 0 0 0 3px #fff;
}

.form-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 48px;
  background: #EFEFEF;
  border: none;
  border-radius: 4px;
  color: inherit;
  font-family: inherit;
  padding: 0.4em 0.8em;
  font-size: 1.6rem;
  letter-spacing: 1.28px;
}

@media screen and (max-width: 767px) {
  .form-input {
    /* sp */
    font-size: 1.4rem;
  }
}

.wpcf7-not-valid-tip {
  color: #AE1E23 !important;
  font-size: 1.2rem !important;
  letter-spacing: 0.96px !important;
  margin-top: 8px !important;
}

.form-textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  resize: none;
  background: #EFEFEF;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 240px;
  padding: 0.4em 0.8em;
  line-height: 1.625;
  color: inherit;
  font-family: inherit;
  font-size: 1.6rem;
  letter-spacing: 1.28px;
}

@media screen and (max-width: 767px) {
  .form-textarea {
    /* sp */
    height: 180px;
    font-size: 1.4rem;
  }
}

.submit__btn {
  text-align: center;
}

.contact__btn {
  display: inline-block;
  padding: 18px 35px;
  background: #AE1E23;
  font-size: 2rem;
  color: #ffffff;
  letter-spacing: 1.6px;
  border-radius: 40px;
  text-align: center;
  transition: opacity .3s;
  border: none;
}

@media screen and (max-width: 767px) {
  .contact__btn {
    /* sp */
    width: 100%;
  }
}

.contact__btn:hover {
  opacity: 0.8;
}

.confirmation_btn {
  background: #AE1E23;
}

.entry-related {
  margin-top: 84px;
}

@media screen and (max-width: 767px) {
  .entry-related {
    /* sp */
    margin-top: 50px;
  }
}

.related-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .related-title {
    /* sp */
    font-size: 1.8rem;
    margin-bottom: 24px;
  }
}

.related-items {
  display: flex;
  flex-wrap: wrap;
}

.related-item {
  display: block;
  margin-bottom: 89px;
  margin-right: 32px;
  width: calc(33.333% - 32px * 2 / 3);
}

.related-item:nth-child(3n) {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .related-item:nth-child(3n) {
    /* sp */
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .related-item {
    /* sp */
    margin-bottom: 40px;
    margin-right: 20px;
    width: calc(50% - 20px * 1 / 2);
  }
  .related-item:nth-child(2n) {
    margin-right: 0;
  }
}

.related-item-img {
  max-width: 312px;
  margin-bottom: 24px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .related-item-img {
    /* sp */
    margin-bottom: 10px;
  }
}

.related-item-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 1.28px;
}

@media screen and (max-width: 767px) {
  .related-item-title {
    /* sp */
    font-size: 1.2rem;
    line-height: 1.4;
  }
}

.font--red {
  color: #AE1E23;
}

.font--42 {
  font-size: 4.2rem;
}

@media screen and (max-width: 767px) {
  .font--42 {
    /* sp */
    font-size: 2.6rem;
  }
}

.font--48 {
  font-size: 4.8rem;
}

@media screen and (max-width: 767px) {
  .font--48 {
    /* sp */
    font-size: 3rem;
  }
}

.font--50 {
  font-size: 5rem;
}

@media screen and (max-width: 767px) {
  .font--50 {
    /* sp */
    font-size: 3.1rem;
  }
}

.font-w {
  font-weight: 600;
}

.footer {
  background: linear-gradient(to right, #221814 0%, #221814 40%, #182350 40%, #182350 100%);
}

.footer__inner {
  display: flex;
  max-width: 984px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .footer__inner {
    /* sp */
    display: block;
  }
}

.footer-left {
  background: #221814;
  color: #EFEFEF;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 7%;
  width: 40%;
}

@media screen and (max-width: 767px) {
  .footer-left {
    /* sp */
    padding-right: 0;
    width: 100%;
  }
}

.footer__logo {
  width: 152px;
}

@media screen and (max-width: 767px) {
  .footer__logo {
    /* sp */
    width: 127px;
  }
}

.footer__info {
  margin: 24px 0;
  padding: 13px 0 16.5px;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

.footer__name {
  font-size: 1.4rem;
  padding-bottom: 15px;
}

.footer__address,
.footer__email {
  font-size: 1.1rem;
  padding-bottom: 15px;
  line-height: 1.5;
}

.footer__number {
  font-size: 1rem;
}

.footer__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__sns {
  font-size: 2rem;
}

.footer__sns a {
  margin-right: 7px;
}

.privacy {
  font-size: 1.2rem;
}

.footer__right {
  background: #182350;
  width: 60%;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .footer__right {
    /* sp */
    width: 100%;
  }
}

.footer__nav {
  display: flex;
  padding-left: 10%;
  padding-right: 10px;
  padding-top: 48px;
}

@media screen and (max-width: 767px) {
  .footer__nav {
    /* sp */
    display: block;
    padding-top: 33px;
    padding-left: 25px;
  }
}

@media screen and (min-width: 768px) {
  .footer__links__left {
    /* pc */
    margin-right: 13%;
  }
}

.footer__links__right .footer__links__item {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .footer__links__right .footer__links__item {
    /* sp */
    margin-bottom: 36px;
  }
}

.footer__links__item {
  font-size: 2rem;
  font-family: "Josefin Sans", sans-serif;
}

@media screen and (max-width: 767px) {
  .footer__links__item {
    /* sp */
    font-size: 1.8rem;
  }
}

.footer__links__item span {
  font-size: 1.1rem;
  margin-left: 16px;
  vertical-align: middle;
}

.sub__menu {
  margin-top: 32px;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .sub__menu {
    /* sp */
    margin-top: 21px;
    margin-bottom: 36px;
    display: flex;
    flex-wrap: wrap;
  }
}

.sub__menu__item {
  font-size: 1.4rem;
  opacity: 0.88;
}

.sub__menu__item:not(:last-child) {
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .sub__menu__item {
    /* sp */
    font-size: 1.2rem;
    margin-right: 30px;
  }
}

.copyright {
  font-size: 1.1rem;
  color: #ffffffe0;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10%;
}

@media screen and (max-width: 767px) {
  .copyright {
    /* sp */
    text-align: center;
    padding-left: 0;
  }
}

.header__logo img {
  max-width: 128px;
}

@media screen and (max-width: 920px) {
  .header__nav {
    background: #EFEFEF;
    color: #182350;
    width: 100%;
    padding: 0 10px;
  }
}

@media screen and (min-width: 768px) {
  .header__links {
    /* pc */
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 920px) {
  .header__links {
    display: block;
    max-width: 280px;
    margin: 87px auto 0;
  }
}

.header__links__item {
  margin-right: 1.5vw;
  font-size: 1.5rem;
  letter-spacing: 1.2px;
}

.header__links__item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 920px) {
  .header__links__item {
    margin-right: 0;
  }
  .header__links__item:not(:first-child) {
    margin-bottom: 30px;
  }
}

/* sp__menu */
@media screen and (min-width: 768px) {
  .sp__menu {
    /* pc */
    display: none;
  }
}

@media screen and (max-width: 920px) {
  .sp__menu {
    display: block;
    margin-top: 25px;
    right: 100%;
  }
}

.sp__menu__item {
  margin-bottom: 26px;
}

.sp__menu__item:last-child {
  margin-bottom: 42px;
}

/* ドロップダウンメニュー */
.dropdown__menu {
  position: relative;
}

.dropdown__menu__items {
  display: flex;
  max-width: 1366px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}

.dropdown__menu__items::before {
  content: "";
  position: absolute;
  top: -21px;
  left: 48%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10.5px 21px 10.5px;
  border-color: transparent transparent #ffffff transparent;
}

.dropdown__menu__item {
  color: #182350;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1.28px;
}

.dropdown__menu__item:not(:last-child) {
  margin-right: 56px;
}

.dropdown__menu__item a {
  display: block;
  height: 100px;
  line-height: 100px;
}

/* drawerメニューカスタマイズ */
.drawer--right.drawer-open .drawer-hamburger {
  right: 0;
}

@media screen and (max-width: 920px) {
  .drawer--right .drawer-nav {
    right: 100%;
  }
}

.heading1 {
  text-align: center;
  font-size: 4rem;
  font-family: "Josefin Sans", sans-serif;
  color: #ffffff;
  letter-spacing: .1em;
}

@media screen and (max-width: 767px) {
  .heading1 {
    /* sp */
    font-size: 3rem;
  }
}

.heading1 span {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 1.2vh;
}

@media screen and (max-width: 767px) {
  .heading1 span {
    /* sp */
    font-size: 1.2rem;
  }
}

.heading2 {
  font-size: 4.6rem;
  color: #182350;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  letter-spacing: 2.69px;
}

@media screen and (max-width: 767px) {
  .heading2 {
    /* sp */
    font-size: 2.8rem;
  }
}

.heading2::first-letter {
  color: #AE1E23;
}

.heading3 {
  font-size: 3.2rem;
  letter-spacing: 2.56px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .heading3 {
    /* sp */
    font-size: 2.4rem;
  }
}

.message__inner {
  margin-top: 72px;
  display: flex;
  align-items: top;
}

@media screen and (max-width: 767px) {
  .message__inner {
    /* sp */
    display: block;
    margin-top: 35px;
    margin: 30px auto 0;
  }
}

.message__img {
  width: 376px;
  height: 376px;
}

.message__img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .message__img {
    /* sp */
    width: 80%;
    height: auto;
    margin: 0 auto;
  }
}

.message__text {
  width: 58.7%;
  margin-left: 3.7%;
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .message__text {
    /* sp */
    width: 100%;
    margin-top: 35px;
    margin-left: 0;
  }
}

.message__text p {
  font-size: 1.6rem;
  font-family: YakuHanMPs_Noto, "Noto Serif JP", serif;
  line-height: 1.5;
  margin-bottom: 24px;
  letter-spacing: 0.9px;
}

@media screen and (max-width: 767px) {
  .message__text p {
    /* sp */
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 15px;
  }
}

.message__text p:last-child {
  margin-bottom: 0;
  text-align: right;
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 1.01px;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .message__text p:last-child {
    /* sp */
    font-size: 1.8rem;
  }
}

.news-entry {
  max-width: 800px;
  margin: 70px auto 0;
}

@media screen and (max-width: 767px) {
  .news-entry {
    /* sp */
    margin-top: 40px;
  }
}

.news-entry-title {
  font-size: 3rem;
  letter-spacing: 2.4px;
  line-height: 1.8;
  font-weight: 600;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .news-entry-title {
    /* sp */
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}

.news-entry-img {
  max-width: 800px;
  padding-top: 50%;
  position: relative;
}

.news-entry-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .news-entry-img {
    /* sp */
    width: 100%;
    height: 50%;
  }
}

.news-entry-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 41px;
}

@media screen and (max-width: 767px) {
  .news-entry-meta {
    /* sp */
    margin-bottom: 15px;
  }
}

.news-entry-label {
  display: inline-block;
  color: #ffffff;
  background: #449AC5;
  padding: 9px 13px;
  font-size: 1.2rem;
  border-radius: 16px;
  letter-spacing: 1.28px;
}

@media screen and (max-width: 767px) {
  .news-entry-label {
    /* sp */
    padding: 3px 8px;
  }
}

.news-entry-published {
  font-size: 1.6rem;
  font-family: "Josefin Sans", sans-serif;
  letter-spacing: 1.28px;
}

.news-entry-text {
  letter-spacing: 1.44px;
  opacity: 0.8;
  margin-bottom: 80px;
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .news-entry-text {
    /* sp */
    margin-bottom: 20px;
    line-height: 1.2;
  }
}

.news-entry-body h2 {
  font-size: 2rem;
  line-height: 1.8;
  margin-bottom: 40px;
  letter-spacing: 1.6px;
  background: #EFEFEF;
  border-top: 6px solid #449AC5;
  padding: 33px 38px;
}

@media screen and (max-width: 767px) {
  .news-entry-body h2 {
    /* sp */
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 1.2;
    padding: 10px;
  }
}

.news-entry-body h3 {
  background: #EFEFEF;
  padding: 33px 38px;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: 1.6px;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .news-entry-body h3 {
    /* sp */
    margin-bottom: 20px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    line-height: 1.2;
    padding: 10px;
  }
}

.news-entry-body p {
  letter-spacing: 1.44px;
  opacity: 0.8;
  margin-bottom: 40px;
  line-height: 1.7;
  padding: 0 40px;
}

@media screen and (max-width: 767px) {
  .news-entry-body p {
    /* sp */
    margin-bottom: 20px;
    line-height: 1.2;
    padding: 0;
  }
}

.news-entry-cv {
  height: 248px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 2px solid #EFEFEF;
}

.news-entry-btn {
  margin-top: 48px;
  text-align: center;
}

.news__items {
  margin: 70px auto 0;
  width: 800px;
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  .news__items {
    /* sp */
    margin-top: 38px;
  }
}

.news__item {
  border-bottom: 2px solid #EFEFEF;
}

.info {
  padding: 28px 35px 28px 0;
  display: flex;
}

@media screen and (max-width: 767px) {
  .info {
    /* sp */
    display: block;
    padding: 12px 0;
  }
}

.info__meta {
  width: 20.5%;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .info__meta {
    /* sp */
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 0;
  }
}

.info__contents {
  width: 79.5%;
}

@media screen and (min-width: 768px) {
  .info__contents {
    /* pc */
    width: 100%;
  }
}

.info__published {
  display: inline-block;
  font-size: 2rem;
  opacity: 0.56;
  font-family: "Josefin Sans", sans-serif;
}

@media screen and (max-width: 767px) {
  .info__published {
    /* sp */
    font-size: 1.6rem;
    margin-right: 10px;
  }
}

.info__label {
  margin-top: 12px;
  display: inline-block;
  color: #ffffff;
  background: #449AC5;
  padding: 6px 13px;
  font-size: 1.2rem;
  border-radius: 16px;
}

.info__label a {
  display: inline-block;
  color: #ffffff;
  background: #449AC5;
  padding: 6px 13px;
  font-size: 1.2rem;
  border-radius: 16px;
}

@media screen and (max-width: 767px) {
  .info__label {
    /* sp */
    margin-top: 0;
    padding: 3px 8px;
  }
}

.info__title {
  font-weight: 600;
  letter-spacing: 1.44px;
}

@media screen and (max-width: 767px) {
  .info__title {
    /* sp */
    width: 100%;
    margin-top: 10px;
  }
}

.info__text {
  opacity: 0.72;
  margin-top: 16px;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .info__text {
    /* sp */
    margin-top: 10px;
  }
}

.outline_inner {
  max-width: 984px;
  margin: 57px auto 0;
  background: #ffffff;
  padding: 17px 33px 14px 30px;
}

@media screen and (max-width: 767px) {
  .outline_inner {
    /* sp */
    margin-top: 28px;
    width: 100%;
    padding: 10px;
  }
}

.outline_inner dl {
  font-size: 1.5rem;
  display: flex;
  padding: 17px 0;
  border-bottom: 2px solid #EFEFEF;
}

@media screen and (max-width: 767px) {
  .outline_inner dl {
    /* sp */
    padding: 10px;
    display: block;
  }
}

.outline_inner dt {
  color: #182350;
  font-weight: 600;
  width: 20%;
  padding-left: 25px;
}

@media screen and (max-width: 767px) {
  .outline_inner dt {
    /* sp */
    padding-left: 0;
    width: 100%;
  }
}

.outline_inner dd {
  width: 80%;
  padding-left: 60px;
}

@media screen and (max-width: 767px) {
  .outline_inner dd {
    /* sp */
    margin-top: 5px;
    padding-left: 0;
    width: 100%;
  }
}

.outline_inner dd a {
  color: #449AC5;
  text-decoration: underline;
}

.pagenation {
  margin-top: 12px;
  margin-bottom: 136px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .pagenation {
    /* sp */
    margin-bottom: 60px;
  }
}

.page-numbers {
  background: #EFEFEF;
  color: #182350;
  display: inline-block;
  font-family: "Josefin Sans", sans-serif;
  font-size: 2.4rem;
  margin-right: 24px;
  text-align: center;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-weight: 600;
}

.page-numbers:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .page-numbers {
    /* sp */
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
  }
}

.post-type-archive-voice .pagenation .current {
  background: #AE1E23;
  color: #ffffff;
}

.post-type-archive-blog .pagenation .current {
  background: #D3521E;
  color: #ffffff;
}

.post-type-archive-news .pagenation .current {
  background: #449AC5;
  color: #ffffff;
}

.pagenation a.next,
.pagenation a.prev {
  font-size: 2rem;
}

.page-numbers.dots,
.page-numbers.next,
.page-numbers.prev {
  color: #182350;
  border: none;
  background: initial;
  width: auto;
}

.privacypolicy {
  margin: 80px 0;
}

@media screen and (max-width: 767px) {
  .privacypolicy {
    /* sp */
    margin: 40px 0;
  }
}

.privacypolicy p {
  line-height: 1.6;
  margin-bottom: 40px;
}

.privacypolicy h2 {
  display: block;
  padding: 8px 0 8px 12px;
  border-left: solid 4px #182350;
  background-color: #EFEFEF;
  font-size: 2.4rem;
  color: #182350;
  font-weight: 600;
  margin: 56px 0 40px 0;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .privacypolicy h2 {
    /* sp */
    font-size: 2rem;
  }
}

.privacypolicy h3 {
  margin-bottom: 40px;
}

.privacypolicy ol {
  margin: 0 0 40px 0;
  counter-reset: item;
  list-style-position: inside;
}

.privacypolicy ol li {
  list-style-type: decimal;
  margin: 8px 0;
}

.profiles__inner {
  max-width: 1000px;
  margin: 74px auto 0;
}

@media screen and (max-width: 767px) {
  .profiles__inner {
    /* sp */
    margin-top: 40px;
  }
}

.profiles__item {
  display: flex;
  border-bottom: 2px solid #EFEFEF;
  padding-bottom: 53px;
}

.profiles__item:nth-child(2) {
  padding-top: 53px;
}

@media screen and (max-width: 767px) {
  .profiles__item:nth-child(2) {
    /* sp */
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .profiles__item {
    /* sp */
    display: block;
    padding: 0 0 20px;
  }
}

.profiles__left {
  width: 28.8%;
}

@media screen and (max-width: 767px) {
  .profiles__left {
    /* sp */
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .profiles__right {
    /* pc */
    width: 71.2%;
    margin-left: 4%;
  }
}

@media screen and (min-width: 768px) {
  .profiles__img {
    /* pc */
    position: relative;
    padding-top: 100%;
  }
  .profiles__img img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .profiles__img {
    /* sp */
    margin: 0 auto;
    max-height: 100%;
    max-width: 280px;
    height: 280px;
  }
  .profiles__img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.profiles__meta {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 2.2rem;
  letter-spacing: 1.76px;
  line-height: 1.636;
}

.position {
  display: block;
  font-size: 1.7rem;
  opacity: 0.72;
  letter-spacing: 0.1em;
  margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .position {
    /* sp */
    display: inline-block;
    margin-right: 10px;
    font-size: 1.5rem;
  }
}

.profiles__sns a {
  color: #182350;
  font-size: 2rem;
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  .profiles__sns a {
    /* sp */
    margin-left: 10px;
  }
}

.profiles__item__text {
  opacity: 0.72;
  line-height: 1.8;
  font-size: 1.5rem;
  letter-spacing: 0.72px;
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .profiles__item__text {
    /* sp */
    margin-top: 20px;
    font-size: 1.5rem;
  }
}

/*==================================================
# service
====================================================*/
/* service__catch
------------------------------------------------------*/
.service__catch {
  margin-top: 74px;
  margin-bottom: 124px;
}

@media screen and (max-width: 767px) {
  .service__catch {
    /* sp */
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.catch__title {
  font-size: 3.2rem;
  letter-spacing: 2.56px;
  text-align: center;
  line-height: 1.6875;
}

@media screen and (max-width: 767px) {
  .catch__title {
    /* sp */
    font-size: 2.4rem;
  }
}

.catch__text {
  margin-top: 52px;
  max-width: 890px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.9rem;
  letter-spacing: 1.52px;
  opacity: 0.8;
  text-align: center;
  line-height: 1.6105;
}

@media screen and (max-width: 767px) {
  .catch__text {
    /* sp */
    margin-top: 25px;
  }
}

/* service__about
------------------------------------------------------*/
.service__about {
  background: #EFEFEF;
  padding: 82px 0 56px;
}

@media screen and (max-width: 767px) {
  .service__about {
    /* sp */
    padding: 40px 0;
  }
}

.about__img {
  margin-top: 54px;
  margin-right: auto;
  margin-left: auto;
  max-width: 720px;
  padding-top: 42.2%;
  position: relative;
}

.about__img img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .about__img {
    /* sp */
    margin-top: 20px;
    padding-top: 67.5%;
  }
}

.about__text {
  margin: 40px auto 0;
  max-width: 690px;
  letter-spacing: 1.44px;
  opacity: 0.8;
  line-height: 1.7;
}

.about_btn {
  text-align: center;
  margin-top: 65px;
}

@media screen and (max-width: 767px) {
  .about_btn {
    /* sp */
    margin-top: 30px;
  }
}

/* feature
------------------------------------------------------*/
.feature {
  padding: 71px 0 91px;
}

@media screen and (max-width: 767px) {
  .feature {
    /* sp */
    padding: 40px 0;
  }
}

.feature__items {
  display: flex;
  margin-top: 49px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .feature__items {
    /* sp */
    display: block;
    margin-top: 30px;
  }
}

.feature__item {
  width: calc(33.33333333% - 30px * 2 / 3);
}

.feature__item:not(:last-child) {
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .feature__item {
    /* sp */
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }
  .feature__item:not(:last-child) {
    margin-bottom: 40px;
  }
}

.feature__img {
  text-align: center;
  padding-top: 67.56%;
  position: relative;
}

.feature__img img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .feature__img {
    /* sp */
    max-width: 100%;
  }
}

.feature__title {
  letter-spacing: 1.44px;
  line-height: 1.6;
  margin-top: 24px;
  font-weight: bold;
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .feature__title {
    /* sp */
    margin-top: 10px;
    font-size: 1.6rem;
  }
}

.feature__content {
  margin-top: 25px;
  font-size: 1.6rem;
  opacity: 0.8;
  letter-spacing: 1.28px;
  text-align: justify;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .feature__content {
    /* sp */
    margin-top: 15px;
  }
}

/* staff
------------------------------------------------------*/
.staff {
  padding: 72px 0 78px;
  background: #EFEFEF;
}

@media screen and (max-width: 767px) {
  .staff {
    /* sp */
    padding: 40px 0;
  }
}

.staff__item {
  display: flex;
  align-items: flex-start;
  margin-top: 72px;
  border-bottom: 2px solid rgba(187, 187, 187, 0.4);
}

@media screen and (max-width: 767px) {
  .staff__item {
    /* sp */
    display: block;
    margin-top: 30px;
    border-bottom: none;
  }
}

.staff__img {
  width: 29%;
  text-align: center;
}

.staff__img img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .staff__img {
    /* sp */
    width: 80%;
    height: 80%;
    margin: 0 auto;
    text-align: center;
  }
}

.staff__name {
  margin-top: 20px;
  text-align: center;
  letter-spacing: 1.44px;
  opacity: 0.88;
}

@media screen and (min-width: 768px) {
  .staff__body {
    /* pc */
    width: 70%;
    margin-left: 3.8%;
  }
}

@media screen and (max-width: 767px) {
  .staff__body {
    /* sp */
    margin-top: 20px;
  }
}

.staff__catch {
  margin-top: 12px;
  margin-right: 30px;
  opacity: 0.88;
  font-size: 2.2rem;
  letter-spacing: 1.76px;
}

@media screen and (max-width: 767px) {
  .staff__catch {
    /* sp */
    font-size: 1.8rem;
    text-align: center;
  }
}

.staff__text {
  opacity: 0.88;
  margin-top: 36px;
  line-height: 1.6;
  letter-spacing: 1.44px;
}

@media screen and (max-width: 767px) {
  .staff__text {
    /* sp */
    margin-top: 20px;
    font-size: 1.4rem;
  }
}

.staff__footer {
  margin-top: 34px;
  padding-bottom: 80px;
  display: flex;
}

@media screen and (max-width: 767px) {
  .staff__footer {
    /* sp */
    padding-bottom: 0;
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .staff__footer .btn__blue {
    /* pc */
    margin-left: 3.8%;
  }
}

@media screen and (max-width: 767px) {
  .staff__footer .btn__blue {
    /* sp */
    margin-top: 20px;
  }
}

/* service__voice
------------------------------------------------------*/
.service__voice {
  margin-top: 80px;
}

@media screen and (max-width: 767px) {
  .service__voice {
    /* sp */
    margin-top: 40px;
  }
}

/* document
------------------------------------------------------*/
.document {
  margin-top: 160px;
}

@media screen and (max-width: 767px) {
  .document {
    /* sp */
    margin-top: 80px;
  }
}

.document__item {
  margin-top: 44px;
  height: 352px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bbbbbb;
}

/* other
------------------------------------------------------*/
.other {
  margin-top: 160px;
}

@media screen and (max-width: 767px) {
  .other {
    /* sp */
    margin-top: 80px;
  }
}

.other .heading3 {
  font-family: "Josefin Sans", sans-serif;
}

.other__inner {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .other__inner {
    /* sp */
    display: block;
  }
}

.service_pre {
  width: 47%;
  margin-right: 3%;
}

.service_pre a {
  display: block;
  height: 120px;
  line-height: 120px;
  text-align: center;
  background: #bbbbbb;
  color: #ffffff;
  position: relative;
}

@media screen and (max-width: 767px) {
  .service_pre {
    /* sp */
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.service_next {
  width: 47%;
  margin-left: 3%;
}

.service_next a {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  background: #bbbbbb;
  color: #ffffff;
  position: relative;
}

@media screen and (max-width: 767px) {
  .service_next {
    /* sp */
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

/* contact_area
------------------------------------------------------*/
.contact_area {
  margin-top: 112px;
  margin-bottom: 103px;
  padding: 41px 10px 57px;
  background: #EFEFEF;
}

@media screen and (max-width: 767px) {
  .contact_area {
    /* sp */
    margin-top: 80px;
    margin-bottom: 40px;
  }
}

.contact_area_title {
  font-size: 2.4rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1.92px;
}

@media screen and (max-width: 767px) {
  .contact_area_title {
    /* sp */
    font-size: 2rem;
  }
}

.contact_area_text,
.contact_area_btn {
  margin-top: 43px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .contact_area_text,
  .contact_area_btn {
    /* sp */
    margin-top: 20px;
  }
}

.voice-entry {
  max-width: 800px;
  margin: 74px auto 0;
}

@media screen and (max-width: 767px) {
  .voice-entry {
    /* sp */
    margin-top: 40px;
  }
}

/* voice-entry-header
------------------------------------------------------*/
.voice-entry-title {
  font-size: 3rem;
  letter-spacing: 2.4px;
  line-height: 1.8;
  font-weight: 600;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .voice-entry-title {
    /* sp */
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}

.voice-entry-img {
  max-width: 800px;
  padding-top: 50%;
  position: relative;
}

.voice-entry-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .voice-entry-img {
    /* sp */
    width: 100%;
    height: 50%;
  }
}

.voice-entry-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 41px;
}

@media screen and (max-width: 767px) {
  .voice-entry-meta {
    /* sp */
    margin-bottom: 15px;
  }
}

.voice-entry-label a {
  display: inline-block;
  background: #EFEFEF;
  padding: 9px 16px;
  border-radius: 4px;
  color: #182350;
  font-size: 1.4rem;
  letter-spacing: 1.12px;
}

.voice-entry-customer {
  font-size: 1.4rem;
  color: #182350;
  letter-spacing: 1.12px;
}

.voice-entry-text {
  letter-spacing: 1.44px;
  opacity: 0.8;
  margin-bottom: 80px;
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .voice-entry-text {
    /* sp */
    margin-bottom: 20px;
    line-height: 1.2;
  }
}

/* voice-entry-body
------------------------------------------------------*/
/* costomer */
.customer {
  margin-top: 90px;
}

@media screen and (max-width: 767px) {
  .customer {
    /* sp */
    margin-top: 60px;
  }
}

.customer__item {
  display: flex;
  align-items: center;
  border: 1px solid #AE1E23;
  border-radius: 0px 8px 8px 8px;
  padding: 25px;
  position: relative;
}

.customer__item::before {
  content: "お客様ご紹介";
  position: absolute;
  top: -31px;
  left: -1px;
  background: #AE1E23;
  color: #ffffff;
  letter-spacing: 1.28px;
  padding: 8px 25px;
  border-radius: 8px 8px 0px 0px;
}

.customer__item::after {
  content: "CUSTOMER";
  position: absolute;
  bottom: -13px;
  right: 25px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 5.6rem;
  font-weight: 600;
  color: #AE1E23;
  opacity: 0.07;
  letter-spacing: 1.34px;
}

@media screen and (max-width: 767px) {
  .customer__item::after {
    /* sp */
    font-size: 4rem;
    bottom: -10px;
    right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .customer__item {
    /* sp */
    display: block;
  }
}

.customer__img,
.company-staff__img {
  width: 29.268%;
  text-align: center;
}

.customer__img img,
.company-staff__img img {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .customer__img,
  .company-staff__img {
    /* sp */
    width: 80%;
    height: 80%;
    margin: 0 auto;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .customer__body,
  .company-staff__body {
    /* pc */
    flex: 0 1 100%;
    margin-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  .customer__body,
  .company-staff__body {
    /* sp */
    margin-top: 20px;
  }
}

.customer__name,
.company-staff__name {
  letter-spacing: 1.44px;
}

.customer__text,
.company-staff__text {
  opacity: 0.8;
  margin-top: 16px;
  line-height: 1.6;
  letter-spacing: 1.2px;
}

/* company-staff */
.company-staff {
  margin-top: 31px;
}

.company-staff__item {
  display: flex;
  align-items: center;
  border: 1px solid #182350;
  border-radius: 8px;
  padding: 25px;
  position: relative;
}

.company-staff__item::after {
  content: "GLOREN";
  position: absolute;
  bottom: -14px;
  right: 25px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 5.6rem;
  font-weight: 600;
  color: #182350;
  opacity: 0.07;
  letter-spacing: 1.34px;
}

@media screen and (max-width: 767px) {
  .company-staff__item::after {
    /* sp */
    font-size: 4rem;
    bottom: -10px;
    right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .company-staff__item {
    /* sp */
    display: block;
  }
}

/* voice-entry-report */
.voice-entry-report {
  margin-top: 102px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .voice-entry-report {
    /* sp */
    margin-top: 50px;
  }
}

.voice-entry-report h2 {
  font-size: 2rem;
  line-height: 1.8;
  margin-bottom: 40px;
  letter-spacing: 1.6px;
  background: #EFEFEF;
  border-top: 6px solid #AE1E23;
  padding: 33px 38px;
}

@media screen and (max-width: 767px) {
  .voice-entry-report h2 {
    /* sp */
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 1.2;
    padding: 10px;
  }
}

.voice-entry-report h3 {
  background: #EFEFEF;
  padding: 33px 38px;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: 1.6px;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .voice-entry-report h3 {
    /* sp */
    margin-bottom: 20px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    line-height: 1.2;
    padding: 10px;
  }
}

.voice-entry-report > p {
  letter-spacing: 1.44px;
  opacity: 0.8;
  margin-bottom: 40px;
  line-height: 1.7;
  padding: 0 40px;
}

@media screen and (max-width: 767px) {
  .voice-entry-report > p {
    /* sp */
    margin-bottom: 20px;
    line-height: 1.2;
    padding: 0;
  }
}

.voice-entry-report figcaption {
  margin-top: 16px;
  margin-bottom: 55px;
  display: block;
  letter-spacing: 1.28px;
  opacity: 0.4;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .voice-entry-report figcaption {
    /* sp */
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}

/* 吹き出し スタッフ*/
.staff-comment {
  width: 100%;
  margin-top: 65px;
  overflow: hidden;
  display: flex;
  align-items: top;
}

@media screen and (max-width: 767px) {
  .staff-comment {
    /* sp */
    margin-top: 30px;
  }
}

.staff-icon img {
  width: 77px;
  height: 77px;
  border-radius: 50%;
  object-fit: cover;
}

.staff-comment-text {
  width: 84.5%;
  position: relative;
  margin-left: 30px;
  background: #d9dff9;
  padding: 17px 13px;
  border-radius: 10px;
  padding: 31px 33px;
  letter-spacing: 1.44px;
  line-height: 1.7777;
}

.staff-comment-text:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 18px;
  left: -24px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 40px 15px 0;
  border-color: transparent #d9dff9 transparent transparent;
}

@media screen and (max-width: 767px) {
  .staff-comment-text {
    /* sp */
    padding: 15px;
    line-height: 1.5;
  }
}

/* 吹き出し カスタマー*/
.customer-comment {
  width: 100%;
  margin-top: 65px;
  margin-bottom: 88px;
  overflow: hidden;
  display: flex;
  align-items: top;
}

@media screen and (max-width: 767px) {
  .customer-comment {
    /* sp */
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.customer-icon img {
  width: 77px;
  height: 77px;
  border-radius: 50%;
  object-fit: cover;
}

.customer-comment-text {
  width: 84.5%;
  position: relative;
  margin-right: 30px;
  background: #f7e5e5;
  padding: 17px 13px;
  border-radius: 10px;
  padding: 31px 33px;
  letter-spacing: 1.44px;
  line-height: 1.7777;
}

.customer-comment-text:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 18px;
  right: -24px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 40px;
  border-color: transparent transparent transparent #f7e5e5;
}

@media screen and (max-width: 767px) {
  .customer-comment-text {
    /* sp */
    padding: 15px;
    line-height: 1.5;
  }
}

.voice-entry-cv {
  height: 248px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 2px solid #EFEFEF;
}

.voice-entry-btn {
  margin-top: 48px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .sns_share_btn {
    /* sp */
    display: none;
  }
}

.sns__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 550px;
  right: 30px;
  z-index: 10;
}

.sns_text {
  font-family: "Josefin Sans", sans-serif;
  color: #182350;
  letter-spacing: 1.12px;
  font-size: 1.4rem;
  margin-bottom: 1em;
}

.sns__link {
  width: 49px;
}

.sns__link:not(:last-child) {
  margin-bottom: 1em;
}

@media screen and (max-width: 767px) {
  .voices {
    /* sp */
  }
}

.voices__items {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .voices__items {
    /* sp */
    display: block;
  }
}

.voices__item {
  width: calc(33.33333333% - 56px * 2 / 3);
  margin-right: 5.6%;
  margin-top: 40px;
  padding-bottom: 52px;
  border-bottom: 2px solid #EFEFEF;
}

@media screen and (max-width: 767px) {
  .voices__item {
    /* sp */
    width: 100%;
    margin-right: 0;
  }
}

.voices__item:nth-child(3n) {
  margin-right: 0;
}

.voices__img {
  max-width: 296px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .voices__img {
    /* sp */
    width: 100%;
    margin: 0 auto;
  }
}

.voices__body {
  color: #6f7579;
}

.voices__label {
  display: inline-block;
  background: #EFEFEF;
  padding: 6px 7px;
  border-radius: 4px;
  margin-top: 16px;
  color: #182350;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
}

.voices__content {
  margin-top: 16px;
  font-size: 1.6rem;
  opacity: 0.88;
  letter-spacing: 1.28px;
  line-height: 1.6;
}

/* voice詳細ページの関連記事のラベル */
.voices__label--related {
  margin-top: 0px;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .voices__label--related {
    /* sp */
    margin-bottom: 8px;
    font-size: 1rem;
  }
}

/* top-page aboutセクション
---------------------------- */
.about {
  margin-top: 50px;
  background: #efefef;
  border-radius: 0 50px 0 50px;
}

@media screen and (max-width: 767px) {
  .about {
    /* sp */
    border-radius: 0 100px 0 50px;
  }
}

.description {
  padding: 64px;
}

@media screen and (max-width: 767px) {
  .description {
    /* sp */
    padding: 20px;
  }
}

.description__title {
  font-size: 3.6rem;
  color: #182350;
  font-weight: 600;
  line-height: 1.8666;
}

@media screen and (max-width: 767px) {
  .description__title {
    /* sp */
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

.description__text {
  width: 80%;
  margin-top: 56px;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: 1.6px;
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .description__text {
    /* sp */
    width: 100%;
    margin-top: 20px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.description__footer {
  margin-top: 48px;
}

@media screen and (max-width: 767px) {
  .description__footer {
    /* sp */
    margin-top: 20px;
    text-align: center;
  }
}

.mv {
  height: auto;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .mv {
    /* sp */
  }
}

.mv__wrapper {
  display: flex;
  align-items: center;
  padding: 0 30px;
}

@media screen and (max-width: 767px) {
  .mv__wrapper {
    /* sp */
    display: block;
    padding: 0 15px;
  }
}

.mv__img {
  width: 49.53%;
}

@media screen and (max-width: 767px) {
  .mv__img {
    /* sp */
    width: 100%;
    margin: 30px auto;
  }
}

.catchcopy {
  margin-left: 3.3%;
  position: relative;
  max-width: 514px;
  width: 49.53%;
  text-align: center;
  color: #182350;
  font-size: 2.8rem;
  line-height: 1.5;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .catchcopy {
    /* sp */
    margin: 0 auto;
    width: 100%;
    font-size: 1.8rem;
  }
}

.line-1 {
  max-width: 100%;
  position: absolute;
  top: -200px;
  right: -150px;
}

.line-2 {
  position: absolute;
  top: 160px;
  left: 120px;
  transform: rotate(65deg);
}

@media screen and (max-width: 767px) {
  .line-2 {
    /* sp */
    top: 50px;
    right: -42px;
    transform: rotate(25deg);
  }
  .line-2 img {
    width: 100px;
  }
}

.line-3 {
  overflow: hidden;
  position: absolute;
  top: 360px;
  right: -120px;
  transform: rotate(-80deg);
}

.news-top__inner {
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  background: #EFEFEF;
  padding: 0 16px;
}

.news-top__items {
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  width: 840px;
  max-width: 100%;
  padding: 60px 0;
}

@media screen and (max-width: 767px) {
  .news-top__items {
    /* sp */
    padding: 30px 0;
  }
}

.news-top__item {
  margin-bottom: 39px;
}

.news-top__item:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .news-top__item {
    /* sp */
    margin-bottom: 20px;
  }
  .news-top__item:last-child {
    margin-bottom: 0;
  }
}

.news-top__link {
  display: flex;
  align-items: top;
}

@media screen and (max-width: 767px) {
  .news-top__link {
    /* sp */
    display: block;
  }
}

.info-top__meta {
  width: 258px;
}

.info-top__published {
  display: inline-block;
  font-size: 2rem;
  opacity: 0.56;
  font-family: "Josefin Sans", sans-serif;
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  .info-top__published {
    /* sp */
    font-size: 1.6rem;
    margin-right: 10px;
  }
}

.info-top__label {
  display: inline-block;
  color: #ffffff;
  background: #449AC5;
  padding: 6px 13px;
  font-size: 1.2rem;
  border-radius: 16px;
}

@media screen and (max-width: 767px) {
  .info-top__label {
    /* sp */
    margin-top: 0;
    padding: 3px 8px;
  }
}

.info-top__title {
  font-weight: 600;
  letter-spacing: 1.44px;
  width: calc(100% - 258px);
}

@media screen and (max-width: 767px) {
  .info-top__title {
    /* sp */
    width: 100%;
    margin-top: 10px;
  }
}

.news-top_footer {
  margin-top: 30px;
  text-align: center;
}

.service {
  max-width: 864px;
  width: 68.78%;
  margin-top: 100px;
  margin-right: 8.9%;
  margin-left: auto;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .service {
    /* sp */
    width: 100%;
    margin-top: 60px;
    margin-right: 0;
    margin-bottom: 60px;
  }
}

.service__list {
  margin-top: 56px;
}

@media screen and (max-width: 767px) {
  .service__list {
    /* sp */
    margin-top: 22px;
  }
}

.service__item {
  display: flex;
  align-items: center;
  margin-bottom: 56px;
}

@media screen and (max-width: 767px) {
  .service__item {
    /* sp */
    display: block;
    padding: 0 20px;
    margin-bottom: 35px;
  }
  .service__item:last-child {
    margin-bottom: 0;
  }
}

.service__item__header {
  max-width: 280px;
}

@media screen and (max-width: 767px) {
  .service__item__header {
    /* sp */
    margin: 0 auto;
    width: 100%;
  }
}

.service__item__body {
  width: calc(100% - 280px);
  margin-left: 40px;
}

@media screen and (max-width: 767px) {
  .service__item__body {
    /* sp */
    width: 100%;
    margin-left: 0;
    margin-top: 21px;
  }
}

.service__item__title {
  font-size: 2.4rem;
  font-weight: bold;
  margin-top: 4px;
  opacity: 0.88;
}

@media screen and (max-width: 767px) {
  .service__item__title {
    /* sp */
    font-size: 2rem;
  }
}

.service__item__text {
  opacity: 0.8;
  margin-top: 24px;
  line-height: 1.6666;
  letter-spacing: 1.44px;
}

@media screen and (max-width: 767px) {
  .service__item__text {
    /* sp */
    margin-top: 10px;
  }
}

.service__footer {
  text-align: right;
  margin-top: 38px;
}

@media screen and (max-width: 767px) {
  .service__footer {
    /* sp */
    margin-top: 10px;
    text-align: center;
  }
}

/* voiceセクション */
.voice {
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .voice {
    /* sp */
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

/* swiperの中身 */
.swiper-container {
  margin-top: 30px;
  background: #AE1E23;
  padding: 32px 0;
}

@media screen and (max-width: 767px) {
  .swiper-container {
    /* sp */
    padding: 20px 15px;
  }
}

.swiper__item {
  display: flex;
  background: #ffffff;
  padding: 5% 3% 2%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #AE1E23;
  border-radius: 8px;
}

@media screen and (max-width: 767px) {
  .swiper__item {
    /* sp */
    display: block;
  }
}

.swiper__item__left {
  max-width: 160px;
}

.swiper__item__left img {
  margin-bottom: 17px;
}

.swiper__item__left p {
  font-size: 1.3rem;
  letter-spacing: 1.04px;
  opacity: 0.88;
  margin-bottom: 7px;
}

@media screen and (max-width: 767px) {
  .swiper__item__left {
    /* sp */
    margin: 0 auto;
    width: 100%;
  }
}

.swiper__item__right {
  width: calc(100% - 280px);
  margin-left: 5%;
}

@media screen and (max-width: 767px) {
  .swiper__item__right {
    /* sp */
    width: 100%;
    margin-left: 0;
    margin-top: 21px;
  }
}

.swiper__item__title {
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 1.92px;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .swiper__item__title {
    /* sp */
    font-size: 1.8rem;
  }
}

.swiper__item__label {
  display: inline-block;
  background: #EFEFEF;
  padding: 6px 7px;
  border-radius: 4px;
  margin-top: 26px;
  color: #182350;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
}

.swiper__item__text {
  margin-top: 24px;
  opacity: 0.88;
  line-height: 1.6;
  letter-spacing: 1.44px;
}

@media screen and (max-width: 767px) {
  .swiper__item__text {
    /* sp */
    margin-top: 10px;
  }
}

.swiper__item__footer {
  text-align: right;
  margin-top: 12px;
}

@media screen and (max-width: 767px) {
  .swiper__item__footer {
    /* sp */
    margin-top: 5px;
    text-align: center;
  }
}

.voice-top_footer {
  text-align: center;
  margin-right: 16px;
  margin-left: 16px;
}

/* ページネーション*/
.swiper-pagination {
  position: static !important;
}

.swiper-pagination-bullet {
  background: #ffffff;
  width: 16px;
  height: 16px;
  border: 1px solid #AE1E23;
  margin: 32px 8px !important;
  opacity: 1;
  position: relative;
}

.swiper-pagination-bullet:focus {
  outline: none;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #AE1E23;
}

/* 左右のアイコン*/
.swiper-button-prev,
.swiper-button-next {
  width: 48px;
  height: 48px;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
}

.swiper-button-prev {
  background: url(../img/arrowL.png) no-repeat center center/contain;
  left: 15%;
}

.swiper-button-next {
  background: url(../img/arrowR.png) no-repeat center center/contain;
  right: 15%;
}
