.privacypolicy {
  margin: 80px 0;
  @include mq(sp) {
    margin: 40px 0;
  }
  p {
    // font-size: 1.6rem;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  h2 {
    display: block;
    padding: 8px 0 8px 12px;
    border-left: solid 4px $navy;
    background-color: $gray;
    font-size: 2.4rem;
    color: $navy;
    font-weight: 600;
    margin: 56px 0 40px 0;
    line-height: 1.5;
    @include mq(sp) {
      font-size: 2rem;
    }
  }
  h3 {
    margin-bottom: 40px;
  }
  ol {
    margin: 0 0 40px 0;
    counter-reset: item;
    list-style-position: inside;
    li {
      list-style-type: decimal;
      margin: 8px 0;
    }
  }
}
