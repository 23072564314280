.description {
  padding: 64px;
  @include mq(sp) {
    padding: 20px;
  }
}

.description__title {
  font-size: 3.6rem;
  color: $navy;
  font-weight: 600;
  line-height: 1.8666;
  // letter-spacing: 0.01em;
  @include mq(sp) {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

.description__text {
  width: 80%;
  margin-top: 56px;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: 1.6px;
  opacity: 0.8;
  @include mq(sp) {
    width: 100%;
    margin-top: 20px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.description__footer {
  margin-top: 48px;
  @include mq(sp) {
    margin-top: 20px;
    text-align: center;
  }
}
