/* レイアウト */
.l-header {
  width: 100%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  @include z-index(header);
  @include mq(pc) {
    padding: 0 $padding-pc;
  }
  @include mq(sp) {
    padding: 0 $padding-sp;
  }
}

.l-content-heading {
  height: 319px;
  // margin-bottom: 4rem;
  // padding-top: 80px;
  background: url(https://placehold.jp/1366x319.png)no-repeat center center / cover;
}

.l-content-heading__inner {
  margin-top: -75px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%
}

.l-wrapper {
  max-width: 1366px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include mq(pc) {
    padding: 0 $padding-pc;
  }
  @include mq(sp) {
    padding: 0 $padding-sp;
  }
}

.l-content-wrapper {
  max-width: 1060px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // box-sizing: content-box;
  @include mq(pc) {
    padding: 0 $padding-pc;
  }
  @include mq(sp) {
    padding: 0 $padding-sp;
  }
}

.section {
  padding: 80px 0;
  @include mq(sp) {
    padding: 50px 0;
  }
}

.section--top {
  margin-bottom: 160px;
  @include mq(sp) {
    margin-bottom: 80px;
  }
}

.section--profiles {
  padding-bottom: 118px;
}

.section--voice, .section--news {
  padding-top: 0;
  @include mq(sp) {
    padding-top: 0;
  }
}
