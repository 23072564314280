/*======================================================================
# common - 全体に共通するスタイル
====================================================================== */
html {
  font-size: 62.5%; /*標準サイズ*/
}

body {
  font-size: 1.8rem;
  color: $black;
  font-family: $hiragino;
  @include mq(sp) {
    font-size: 1.4rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea,
select,
button {
  font-family: inherit;
  appearance: none;
  outline: none;
}

select {
  &::-ms-expand {
    display: none;
  }
}

button {
  cursor: pointer;
  font-size: inherit;
}

// .inner {
//   // max-width: $layout-width-inner;
//   margin: 0 auto;
//   box-sizing: content-box;

//   @include mq(pc) {
//     // 画面を小さくした時にも、両サイドの余白がほしいので
//     padding: 0 $padding-pc;
//   }

//   @include mq(sp) {
//     // 画面を小さくした時にも、両サイドの余白がほしいので
//     padding: 0 $padding-sp;
//   }

//   // @include mq(sp) {
//   //     padding: 0 $padding-sp;
//   // }
// }

// PCのみ表示、スマホの時は表示しない
@include mq(sp) {
  .is-pc {
    display: none;
  }
}

// スマホのみ表示、pcの時は表示しない
@include mq(pc) {
  .is-sp {
    display: none;
  }
}
