/* voiceセクション */
.voice {
  margin-top: 80px;
  margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

/* swiperの中身 */
.swiper-container {
  margin-top: 30px;
  background: $red;
  padding: 32px 0;
  @include mq(sp) {
    padding: 20px 15px;
  }
}

.swiper__item {
  display: flex;
  background: $white;
  // padding: 56px 48px 40px;
  padding: 5% 3% 2%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid $red;
  border-radius: 8px;
  @include mq(sp) {
    display: block;
  }
}

.swiper__item__left {
  max-width: 160px;
  img {
    margin-bottom: 17px;
  }
  p {
    font-size: 1.3rem;
    letter-spacing: 1.04px;
    opacity: 0.88;
    margin-bottom: 7px;
  }
  @include mq(sp) {
    margin: 0 auto;
    width: 100%;
  }
}

.swiper__item__right {
  // flex: 0 1 100%;
  width: calc(100% - 280px);
  margin-left: 5%;
  @include mq(sp) {
    width: 100%;
    margin-left: 0;
    margin-top: 21px;
  }
}

.swiper__item__title {
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 1.92px;
  line-height: 1.6;
  @include mq(sp) {
    font-size: 1.8rem;
  }
}

.swiper__item__label {
  display: inline-block;
  background: $gray;
  padding: 6px 7px;
  border-radius: 4px;
  margin-top: 26px;
  color: $navy;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
}

.swiper__item__text {
  margin-top: 24px;
  opacity: 0.88;
  line-height: 1.6;
  letter-spacing: 1.44px;
  @include mq(sp) {
    margin-top: 10px;
  }
}

.swiper__item__footer {
  text-align: right;
  margin-top: 12px;
  @include mq(sp) {
    margin-top: 5px;
    text-align: center;
  }
}

.voice-top_footer {
  text-align: center;
  margin-right: 16px;
  margin-left: 16px;
}

/* ページネーション*/
.swiper-pagination {
  position: static !important;
}

.swiper-pagination-bullet {
  // bulletの色,サイズ
  background: $white;
  width: 16px;
  height: 16px;
  border: 1px solid $red;
  // bulletの間隔
  margin: 32px 8px !important;
  opacity: 1;
  position: relative;

  &:focus {
    outline: none;
  }

  &.swiper-pagination-bullet-active {
    // アクティブ時のbulletの色
    background: $red;
  }
}

/* 左右のアイコン*/
.swiper-button-prev,
.swiper-button-next {
  // ボタンのサイズ
  width: 48px;
  height: 48px;
  top: 50%;
  transform: translateY(-50%);

  &::after {
    content: "";
  }
}

.swiper-button-prev {
  background: url(../img/arrowL.png) no-repeat center center / contain;
  // 左ボタンの位置
  left: 15%;
}

.swiper-button-next {
  background: url(../img/arrowR.png) no-repeat center center / contain;
  // 右ボタンの位置
  right: 15%;
}
