.news-top__inner {
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  background: $gray;
  padding: 0 16px;
}

.news-top__items {
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  width: 840px;
  max-width: 100%;
  padding: 60px 0;
  @include mq(sp) {
    padding: 30px 0;
  }
}

.news-top__item {
  margin-bottom: 39px;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq(sp) {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.news-top__link {
  display: flex;
  align-items: top;
  // flex-wrap: wrap;
  @include mq(sp) {
    display: block;
  }
}

.info-top__meta {
  width: 258px;
}

.info-top__published {
  display: inline-block;
  font-size: 2rem;
  opacity: 0.56;
  font-family: $en;
  margin-right: 20px;
  @include mq(sp) {
    font-size: 1.6rem;
    margin-right: 10px;
  }
}

.info-top__label {
  display: inline-block;
  color: $white;
  background: $blue;
  padding: 6px 13px;
  font-size: 1.2rem;
  border-radius: 16px;
  @include mq(sp) {
    margin-top: 0;
    padding: 3px 8px;
  }
}

.info-top__title {
  font-weight: 600;
  letter-spacing: 1.44px;
  width: calc(100% - 258px);
  @include mq(sp) {
    width: 100%;
    margin-top: 10px;
  }
}

.news-top_footer {
  margin-top: 30px;
  text-align: center;
}
