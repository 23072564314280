.profiles__inner {
  max-width: 1000px;
  margin: 74px auto 0;
  @include mq(sp) {
    margin-top: 40px;
  }
}

.profiles__item {
  display: flex;
  border-bottom: 2px solid $gray;
  padding-bottom: 53px;
  &:nth-child(2) {
    padding-top: 53px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
  @include mq(sp) {
    display: block;
    padding: 0 0 20px;
  }
}

.profiles__left {
  width: 28.8%;
  @include mq(sp) {
    width: 100%;
    text-align: center;
  }
}

.profiles__right {
  @include mq(pc) {
    width: 71.2%;
    margin-left: 4%;
  }
}

// .profiles__img {
//   // max-height: 288px;
//   // max-width: 288px;
//   // height: 100%;
//   img {
//     // height: 100%;
//     // width: 100%;
//     object-fit: cover;
//   }
//   @include mq(sp) {
//     width: 288px;
//     margin: 0 auto;
//   }
// }

.profiles__img {
  @include mq(pc) {
    position: relative;
    padding-top: 100%;
    img {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    
  }
  @include mq(sp) {
    margin: 0 auto;
    max-height: 100%;
    max-width: 280px;
    height: 280px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.profiles__meta {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 2.2rem;
  letter-spacing: 1.76px;
  line-height: 1.636;
}

.position {
  display: block;
  font-size: 1.7rem;
  opacity: 0.72;
  letter-spacing: 0.1em;
  margin-top: 16px;
  @include mq(sp) {
    display: inline-block;
    // margin-top: 4px;
    margin-right: 10px;
    font-size: 1.5rem;
  }
}

.profiles__sns a { 
  color: $navy;
  font-size: 2rem;
  margin-left: 15px;
  @include mq(sp) {
    margin-left: 10px;
  }
}

.profiles__item__text {
  opacity: 0.72;
  line-height: 1.8;
  font-size: 1.5rem;
  letter-spacing: 0.72px;
  text-align: justify;
  @include mq(sp) {
    margin-top: 20px;
    font-size: 1.5rem;
  }
}
