.voices {
  @include mq(sp) {
    // margin-top: 40px;
  }
}

.voices__items {
  display: flex;
  flex-wrap: wrap;
  @include mq(sp) {
    display: block;
  }
}

.voices__item {
  // カードとカードの間のサイズ*隙間の数/カードの枚数
  width: calc(33.33333333% - 56px * 2 / 3);
  margin-right: 5.6%;
  margin-top: 40px;
  padding-bottom: 52px;
  border-bottom: 2px solid $gray;
  @include mq(sp) {
    width: 100%;
    margin-right: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.voices__img {
  max-width: 296px;
  object-fit: cover;
  @include mq(sp) {
    width: 100%;
    margin: 0 auto;

  }
}

.voices__body {
  color: #6f7579;
}

.voices__label {
  display: inline-block;
  background: $gray;
  padding: 6px 7px;
  border-radius: 4px;
  margin-top: 16px;
  color: $navy;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
}

.voices__content {
  margin-top: 16px;
  font-size: 1.6rem;
  opacity: 0.88;
  letter-spacing: 1.28px;
  line-height: 1.6;
}

/* voice詳細ページの関連記事のラベル */
.voices__label--related {
  margin-top: 0px;
  margin-bottom: 16px;
  @include mq(sp) {
    margin-bottom: 8px;
    font-size: 1rem;
  }
}