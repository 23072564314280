.voice-entry {
  max-width: 800px;
  margin: 74px auto 0;
  @include mq(sp) {
    margin-top: 40px;
  }
}

/* voice-entry-header
------------------------------------------------------*/

.voice-entry-title {
  font-size: 3rem;
  letter-spacing: 2.4px;
  line-height: 1.8;
  font-weight: 600;
  margin-bottom: 30px;
  @include mq(sp) {
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}

.voice-entry-img {
  max-width: 800px;
  padding-top: 50%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
  }
  @include mq(sp) {
    width: 100%;
    height: 50%;
  }
}

.voice-entry-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 41px;
  @include mq(sp) {
    margin-bottom: 15px;
  }
}

.voice-entry-label a {
  display: inline-block;
  background: $gray;
  padding: 9px 16px;
  border-radius: 4px;
  color: $navy;
  font-size: 1.4rem;
  letter-spacing: 1.12px;
}

.voice-entry-customer {
  font-size: 1.4rem;
  color: $navy;
  letter-spacing: 1.12px;
}

.voice-entry-text {
  letter-spacing: 1.44px;
  opacity: 0.8;
  margin-bottom: 80px;
  line-height: 1.7;
  @include mq(sp) {
    margin-bottom: 20px;
    line-height: 1.2;
  }
}

/* voice-entry-body
------------------------------------------------------*/
/* costomer */
.customer {
  margin-top: 90px;
  @include mq(sp) {
    margin-top: 60px;
  }
}

.customer__item {
  display: flex;
  align-items: center;
  border: 1px solid $red;
  border-radius: 0px 8px 8px 8px;
  padding: 25px;
  position: relative;
  &::before {
    content: "お客様ご紹介";
    position: absolute;
    top: -31px;
    left: -1px;
    background: $red;
    color: $white;
    letter-spacing: 1.28px;
    padding: 8px 25px;
    border-radius: 8px 8px 0px 0px;
  }
  &::after {
    content: "CUSTOMER";
    position: absolute;
    bottom: -13px;
    right: 25px;
    font-family: $en;
    font-size: 5.6rem;
    font-weight: 600;
    color: $red;
    opacity: 0.07;
    letter-spacing: 1.34px;
    @include mq(sp) {
      font-size: 4rem;
      bottom: -10px;
      right: 5px;
    }
  }

  @include mq(sp) {
    display: block;
  }
}

.customer__img,
.company-staff__img {
  width: 29.268%;
  text-align: center;
  img {
    width: 144px;
    height: 144px;
    border-radius: 50%;
    object-fit: cover;
  }
  @include mq(sp) {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    text-align: center;
  }
}

.customer__body,
.company-staff__body {
  @include mq(pc) {
    flex: 0 1 100%;
    margin-left: 40px;
  }
  @include mq(sp) {
    margin-top: 20px;
  }
}

.customer__name,
.company-staff__name {
  letter-spacing: 1.44px;
}

.customer__text,
.company-staff__text {
  opacity: 0.8;
  margin-top: 16px;
  line-height: 1.6;
  letter-spacing: 1.2px;
}

/* company-staff */
.company-staff {
  margin-top: 31px;
}

.company-staff__item {
  display: flex;
  align-items: center;
  border: 1px solid $navy;
  border-radius: 8px;
  padding: 25px;
  position: relative;
  &::after {
    content: "GLOREN";
    position: absolute;
    bottom: -14px;
    right: 25px;
    font-family: $en;
    font-size: 5.6rem;
    font-weight: 600;
    color: $navy;
    opacity: 0.07;
    letter-spacing: 1.34px;
    @include mq(sp) {
      font-size: 4rem;
      bottom: -10px;
      right: 5px;
    }
  }

  @include mq(sp) {
    display: block;
  }
}

/* voice-entry-report */
.voice-entry-report {
  margin-top: 102px;
  margin-right: auto;
  margin-left: auto;
  @include mq(sp) {
    margin-top: 50px;
  }
  h2 {
    font-size: 2rem;
    line-height: 1.8;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
    background: $gray;
    border-top: 6px solid $red;
    padding: 33px 38px;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 1.6rem;
      line-height: 1.2;
      padding: 10px;
    }
  }
  h3 {
    background: $gray;
    padding: 33px 38px;
    font-size: 2rem;
    line-height: 1.8;
    letter-spacing: 1.6px;
    margin-bottom: 40px;
    @include mq(sp) {
      margin-bottom: 20px;
      margin-bottom: 20px;
      font-size: 1.5rem;
      line-height: 1.2;
      padding: 10px;
    }
  }
  > p {
    letter-spacing: 1.44px;
    opacity: 0.8;
    margin-bottom: 40px;
    line-height: 1.7;
    padding: 0 40px;
    @include mq(sp) {
      margin-bottom: 20px;
      line-height: 1.2;
      padding: 0;
    }
  }

  figcaption {
    margin-top: 16px;
    margin-bottom: 55px;
    display: block;
    letter-spacing: 1.28px;
    opacity: 0.4;
    font-size: 1.6rem;
    @include mq(sp) {
      font-size: 1.2rem;
      margin-bottom: 20px;
    }
  }
}

/* 吹き出し スタッフ*/
.staff-comment {
  width: 100%;
  // margin: 1.5em 0;
  margin-top: 65px;
  overflow: hidden;
  display: flex;
  align-items: top;
  @include mq(sp) {
    margin-top: 30px;
  }
}

.staff-icon {
  img {
    width: 77px;
    height: 77px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.staff-comment-text {
  width: 84.5%;
  position: relative;
  margin-left: 30px;
  background: #d9dff9;
  padding: 17px 13px;
  border-radius: 10px;
  padding: 31px 33px;
  letter-spacing: 1.44px;
  line-height: 1.7777;
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 18px;
    left: -24px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 40px 15px 0;
    border-color: transparent #d9dff9 transparent transparent;
  }
  @include mq(sp) {
    padding: 15px;
    line-height: 1.5;
  }
}

/* 吹き出し カスタマー*/
.customer-comment {
  width: 100%;
  margin-top: 65px;
  margin-bottom: 88px;
  overflow: hidden;
  display: flex;
  align-items: top;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.customer-icon {
  img {
    width: 77px;
    height: 77px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.customer-comment-text {
  width: 84.5%;
  position: relative;
  margin-right: 30px;
  background: #f7e5e5;
  padding: 17px 13px;
  border-radius: 10px;
  padding: 31px 33px;
  letter-spacing: 1.44px;
  line-height: 1.7777;
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 18px;
    right: -24px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 40px;
    border-color: transparent transparent transparent #f7e5e5;
  }
  @include mq(sp) {
    padding: 15px;
    line-height: 1.5;
  }
}

.voice-entry-cv {
  height: 248px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 2px solid $gray;
}

.voice-entry-btn {
  margin-top: 48px;
  text-align: center;
}
