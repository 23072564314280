.entry-related {
	margin-top: 84px;
  @include mq(sp) {
    margin-top: 50px;
  }
}

.related-title {
	font-size: 2.4rem;
	font-weight: 600;
	margin-bottom: 32px;
  @include mq(sp) {
    font-size: 1.8rem;
    margin-bottom: 24px;
  }
}

.related-items {
	display: flex;
	flex-wrap: wrap;
}

.related-item {
	display: block;
	margin-bottom: 89px;
	margin-right: 32px;
	width: calc(33.333% - 32px * 2 / 3);
  &:nth-child(3n) {
    margin-right: 0;
    @include mq(sp) {
      margin-right: 20px;
    }
  }
  @include mq(sp) {
    margin-bottom: 40px;
    margin-right: 20px;
    width: calc(50% - 20px * 1 / 2);
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

.related-item-img {
  max-width: 312px;
	margin-bottom: 24px;
	text-align: center;
  @include mq(sp) {

    margin-bottom: 10px;
  }
}

.related-item-title {
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.6;
  letter-spacing: 1.28px;
  @include mq(sp) {
    font-size: 1.2rem;
    line-height: 1.4;
  }
}

