/* ボタン
---------------------------- */
.btn {
  display: inline-block;
  padding: 13px 24px;
  border-radius: 20px;
}

.btn__white {
  display: inline-block;
  padding: 12px 41px;
  color: $navy;
  letter-spacing: 1.44px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid $navy;
  border-radius: 24px;
  text-align: center;
  @include mq(sp) {
    padding: 10px 20px;
    width: 100%;
  }
}

.btn__contact {
  display: inline-block;
  padding: 13px 24px;
  border-radius: 20px;
  color: $white;
  background: $red;
  letter-spacing: 1.2px;
  font-size: 1.5rem;
  @include mq(sp) {
    width: 100%;
    text-align: center;
  }
}

.btn--lg {
  display: inline-block;
  border-radius: 40px;
  padding: 22px 124px;
  letter-spacing: 1.6px;
  @include mq(sp) {
    padding: 13px 24px;
  }
}

.btn__description {
  display: inline-block;
  background: $navy;
  color: $white;
  letter-spacing: 1.44px;
  padding: 19px 35px;
  border-radius: 50px;
  box-shadow: 0 16px 32px #18235029;
  @include mq(sp) {
    padding: 15px 20px;
    width: 100%;
  }
}

.btn__blue {
  display: inline-block;
  background: $navy;
  color: $white;
  letter-spacing: 1.44px;
  padding: 12px 41px;
  border-radius: 50px;
  box-shadow: 0 16px 32px #18235029;
  text-align: center;
  @include mq(sp) {
    padding: 10px 20px;
    width: 100%;
  }
}

.btn__blog,
.btn__news {
  display: inline-block;
  text-align: center;
  background: $black;
  color: $white;
  letter-spacing: 1.44px;
  padding: 19px 86px;
  border-radius: 50px;
  box-shadow: 0 16px 32px #18235029;
  @include mq(sp) {
    padding: 10px 20px;
    width: 100%;
  }
}

.btn__swiper {
  display: inline-block;
  font-family: $en;
  font-size: 1.6rem;
  text-align: center;
  background: $black;
  color: $white;
  letter-spacing: 1.28px;
  padding: 12px 27px;
  border-radius: 50px;
  box-shadow: 0 16px 32px #18235029;
  @include mq(sp) {
    // padding: 10px 20px;
    // width: 100%;
  }
}

.btn__single {
  display: inline-block;
  background: $black;
  border-radius: 28px;
  color: $white;
  width: 296px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  box-shadow: 0 3px 6px #00000029;

  @include mq(sp) {
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
}
