// フォントサイズをremで記入する関数。使い方はfont-size: rem(20);のようにする。引数はpxと使える。
@function rem($pixels, $context: $baseFontSize) {
    @return $pixels / $context * 1rem;
}

//line-heightを計算する関数。使い方はline-height: division(40, 10);。
@function division($numerator, $denominator) {

    @return $numerator / $denominator;
}

//inner幅以下になったときはvw指定にする
@function vw($width, $px) {

  @return $px / $width * 100vw;
}

// width: vw(1,000, 24);