/*======================================================================
# 変数定義
====================================================================== */
// 色
$white: #ffffff;
$black: #221814; //文字色
$red: #AE1E23; //アクセントカラー
$yel: #E7B031;
$orange: #D3521E;
$blue: #449AC5;
$navy: #182350; //メインカラー
$gray: #EFEFEF; //背景色

// レイアウトの左右の余白
$padding-pc: 30px;
$padding-sp: 16px;

//フォントファミリー
$noto: YakuHanJP_Noto, 'Noto Sans JP', sans-serif;
$bebas: 'Bebas Neue', cursive; //英数字
$hiragino: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$yugo: "游ゴシック体", "Yu Gothic", YuGothic, Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$helvetica: Helvetica,"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$gothic: YakuHanJPs_Noto, "Noto Sans JP", sans-serif;
$mincho: YakuHanMPs_Noto, "Noto Serif JP", serif;
$en: 'Josefin Sans', sans-serif;

// 階層
$layer: (
	modal: 100, // モダール
	drawer: 40, // ドロワー
	floating: 30, // フローティングアイテム
	header: 20, // ヘッダー
	footer: 10, // フッター
	default: 1,
);


// ヘッダー高さ
// $header-height-pc: 56px;
// $header-height-sp: 50.6px;