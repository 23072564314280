/* 固定ページblogとトップページのブログ
---------------------------- */
.blog__items {
  display: flex;
  flex-wrap: wrap;
  @include mq(sp) {
    display: block;
  }
}

.blog__item {
  // カードとカードの間のサイズ*隙間の数/カードの枚数
  width: calc(33.33333333% - 56px * 2 / 3);
  margin-right: 5.6%;
  margin-top: 40px;
  padding-bottom: 52px;
  border-bottom: 2px solid $gray;
  @include mq(sp) {
    width: 100%;
    margin-right: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.blog__img {
  max-width: 296px;
  object-fit: cover;
  @include mq(sp) {
    width: 100%;
    margin: 0 auto;

  }
}

.blog__body {
  color: #6f7579;
}

.blog__label {
  display: inline-block;
  background: $orange;
  padding: 10px 17px;
  border-radius: 16px;
  margin-top: 16px;
  color: $white;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
}

.blog__content {
  margin-top: 16px;
  font-size: 1.6rem;
  opacity: 0.88;
  font-weight: 600;
  letter-spacing: 1.44px;
  line-height: 1.4222;
}

/* トップのブログのスタイル */
.blog--top {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto 160px;
  @include mq(sp) {
    margin-bottom: 80px;
  }
}

.blog__item--top {
  margin-top: 40px;
  padding-bottom: 0;
  border-bottom: none;

}

.blog__footer {
  text-align: center;
  margin-top: 51px;
}