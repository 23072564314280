@charset "UTF-8";
/*==========================================================================
# reset - ブラウザの差異や不要なスタイルを無くすためのスタイル
========================================================================== */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, h1, h2, h3, h4, h5, h6, ul, ol, dl, li, dt, dd, p, div, span, img, a, table, tr, th, td, small, button, time, figure {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

li, dd {
  list-style-type: none;
}

header, footer, nav, section, article, aside, figure, figcaption {
  display: block;
}

img {
  border: none;
  vertical-align: bottom;
}

a {
  cursor: pointer;
  text-decoration: none;
}