.heading1 {
  text-align: center;
  font-size: 4rem;
  font-family: $en;
  color: $white;
  letter-spacing: .1em;
  @include mq(sp) {
    font-size: 3rem;
  }
  span {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 1.2vh;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
}

.heading2 {
  font-size: 4.6rem;
  color: $navy;
  font-family: $en;
  text-align: center;
  letter-spacing: 2.69px;
  @include mq(sp) {
    font-size: 2.8rem;
  }
  &::first-letter {
    color: $red;
  }
}

.heading3 {
  font-size: 3.2rem;
  letter-spacing: 2.56px;
  text-align: center;
  @include mq(sp) {
    font-size: 2.4rem;
  }
}