.font--red {
  color: $red;
}
.font--42 {
  font-size: 4.2rem;
  @include mq(sp) {
    font-size: 2.6rem;
  }
}
.font--48 {
  font-size: 4.8rem;
  @include mq(sp) {
    font-size: 3rem;
  }
}
.font--50 {
  font-size: 5rem;
  @include mq(sp) {
    font-size: 3.1rem;
  }
}

.font-w {
  font-weight: 600;
}