.header__logo {
  img {
    // max-height: 35px;
    max-width: 128px;
  }
}

.header__nav {
  //  @include mq(sp) {
  //     background: $gray;
  //     color: $navy;
  //     width: 100%;
  //     padding: 0 10px;
  //   }
  @media screen and (max-width: 920px) {
    background: $gray;
    color: $navy;
    width: 100%;
    padding: 0 10px;
  }
  // @include mq(sp) {
  //   background: $gray;
  //   color: $navy;
  //   width: 100%;
  //   padding: 0 10px;
  // }
}
.header__links {
  @include mq(pc) {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 920px) {
    display: block;
    max-width: 280px;
    margin: 87px auto 0;
  }
  // @include mq(sp) {
  //    display: block;
  //   max-width: 280px;
  //   margin: 87px auto 0;
  // }
}

.header__links__item {
  margin-right: vw(1000, 15);
  font-size: 1.5rem;
  letter-spacing: 1.2px;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 920px) {
    margin-right: 0;
    &:not(:first-child) {
      margin-bottom: 30px;
    }
  }
}

/* sp__menu */

.sp__menu {
  @include mq(pc) {
    display: none;
  }
  @media screen and (max-width: 920px) {
    display: block;
    margin-top: 25px;
    right: 100%;
  }
}

.sp__menu__item {
  margin-bottom: 26px;
  &:last-child {
    margin-bottom: 42px;
  }
}

/* ドロップダウンメニュー */
.dropdown__menu {
  position: relative;
}

.dropdown__menu__items {
  display: flex;
  max-width: 1366px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: $white;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  &::before {
    content: "";
    position: absolute;
    top: -21px;
    left: 48%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10.5px 21px 10.5px;
    border-color: transparent transparent $white transparent;
  }
}
.dropdown__menu__item {
  color: $navy;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1.28px;
  &:not(:last-child) {
    margin-right: 56px;
  }
}
.dropdown__menu__item a {
  display: block;
  height: 100px;
  line-height: 100px;
}

/* drawerメニューカスタマイズ */
// メニューの位置
.drawer--right.drawer-open .drawer-hamburger {
  right: 0;
}

.drawer--right .drawer-nav {
  @media screen and (max-width: 920px) {
    right: 100%;
  }
}

//---------------------------------------------
// #global-nav{
//     ul {
//         margin:0 auto;
//         text-align:center;
//         li {
//             display: inline-block;
//             margin-right: 1vw;
//             a {
//                 font-size: 12px;
//                 display: block;
//             }
//             &.outline {
//                 a {
//                     cursor: default;
//                 }
//                 ul {
//                     position: fixed;
//                     left: 0;
//                     margin: 0;
//                     width: 100%;
//                     height: 50px;
//                     opacity: 0;
//                     top: 12vh;
//                     visibility: hidden;
//                     transition: .5s;
//                     background-color: #CCC;
//                     &:after {
//                         content: "";
//                         position: absolute;
//                         top: -10px;
//                         margin-left: -10px;
//                         display: block;
//                         width: 0;
//                         height: 0;
//                         border-style: solid;
//                         border-width: 0 10px 10px 10px;
//                         border-color: transparent transparent #CCC transparent;
//                     }
//                     li {
//                         width: 15%;
//                         position: relative;
//                         color: #000;
//                         vertical-align: top;
//                         a {
//                             margin: 0 auto;
//                             text-align: center;
//                             background: #FFF;
//                             font-weight: normal;
//                             padding: 5vh 0 0;
//                             height: 30px;
//                             text-align: center;
//                             letter-spacing: 0;
//                             cursor: pointer;
//                             color: #CCC;
//                             border-radius: 5px;
//                             border: none;
//                             margin-top: 1vh;
//                         }
//                     }
//                 }
//                 &:hover {
//                   ul {
//                     visibility: visible;
//                     opacity: 1;
//                     z-index: 900;
//                     li {
//                       overflow: visible;
//                       opacity: 1;
//                       a {
//                         opacity: 1;
//                       }
//                     }
//                   }
//                 }
//             }
//         }
//     }
// }
//---------------------------------------------
